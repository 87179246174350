@import './../tools.scss';



@media screen and (min-width: 375px) and (max-width:575px){
    .footer {
        background-color: $mainColor;
        @include setPadding(50px 20px 35px);
        @include setFlex(flex, column, space-between, flex-start);
    
        .footer-contacts {
            width: $sizePercent;
            @include setFlex(flex, column, center, center);
            @include setPadding(0 0 50px);
    
            .phone {
                color: $uniqueColor;
                font-size: 0.9rem;
                transition: color 0.3s ease;
                @include setMargin(0 0 10px);
    
                .phone-text {
                    @include setMargin(0 0 0 10px);
                }
    
                &:hover {
                    color: $primaryColor;
                }
            }
        }
    
        .footer-info {
            width: $sizePercent;
            @include setFlex(flex, column, center, center);
    
            .info-text {
                font-size: 0.7rem;
                color: $uniqueColor;
                text-align: center;
                @include setPadding(0 0 5px);
            }
        }
    }
}

@media screen and (min-width: 576px) and (max-width:767px){
    .footer {
        background-color: $mainColor;
        @include setPadding(50px 20px 35px);
        @include setFlex(flex, column, space-between, flex-start);
    
        .footer-contacts {
            width: $sizePercent;
            @include setFlex(flex, column, center, center);
            @include setPadding(0 0 50px);
    
            .phone {
                color: $uniqueColor;
                font-size: 0.9rem;
                transition: color 0.3s ease;
                @include setMargin(0 0 10px);
    
                .phone-text {
                    @include setMargin(0 0 0 10px);
                }
    
                &:hover {
                    color: $primaryColor;
                }
            }
        }
    
        .footer-info {
            width: $sizePercent;
            @include setFlex(flex, column, center, center);
    
            .info-text {
                font-size: 0.7rem;
                color: $uniqueColor;
                text-align: center;
                @include setPadding(0 0 5px);
            }
        }
    }
}


@media screen and (min-width: 768px) and (max-width:991px){
    .footer {
        background-color: $mainColor;
        @include setPadding(50px 20px 35px);
        @include setFlex(flex, column, space-between, flex-start);
    
        .footer-contacts {
            width: $sizePercent;
            @include setFlex(flex, column, center, center);
            @include setPadding(0 0 50px);
    
            .phone {
                color: $uniqueColor;
                font-size: 0.9rem;
                transition: color 0.3s ease;
                @include setMargin(0 0 10px);
    
                .phone-text {
                    @include setMargin(0 0 0 10px);
                }
    
                &:hover {
                    color: $primaryColor;
                }
            }
        }
    
        .footer-info {
            width: $sizePercent;
            @include setFlex(flex, column, center, center);
    
            .info-text {
                font-size: 0.7rem;
                color: $uniqueColor;
                text-align: center;
                @include setPadding(0 0 5px);
            }
        }
    }
}

@media screen and (min-width: 992px) and (max-width:1199px){
    .footer {
        background-color: $mainColor;
        @include setPadding(50px 50px 35px);
        @include setFlex(flex, row, space-between, flex-start);
    
        .footer-contacts {
            width: $sizePercent;
            @include setFlex(flex, column, flex-start, flex-start);
            @include setPadding(0);
    
            .phone {
                color: $uniqueColor;
                font-size: 0.9rem;
                transition: color 0.3s ease;
                @include setMargin(0 0 10px);
    
                .phone-text {
                    @include setMargin(0 0 0 10px);
                }
    
                &:hover {
                    color: $primaryColor;
                }
            }
        }
    
        .footer-info {
            width: $sizePercent;
            @include setFlex(flex, column, flex-end, flex-end);
    
            .info-text {
                font-size: 0.9rem;
                color: $uniqueColor;
                text-align: center;
                @include setPadding(0 0 5px);
            }
        }
    }
}

@media screen and (min-width: 1200px) and (max-width:1399px){
    .footer {
        background-color: $mainColor;
        @include setPadding(50px 50px 35px);
        @include setFlex(flex, row, space-between, flex-start);
    
        .footer-contacts {
            width: $sizePercent;
            @include setFlex(flex, column, flex-start, flex-start);
            @include setPadding(0);
    
            .phone {
                color: $uniqueColor;
                font-size: 0.9rem;
                transition: color 0.3s ease;
                @include setMargin(0 0 10px);
    
                .phone-text {
                    @include setMargin(0 0 0 10px);
                }
    
                &:hover {
                    color: $primaryColor;
                }
            }
        }
    
        .footer-info {
            width: $sizePercent;
            @include setFlex(flex, column, flex-end, flex-end);
    
            .info-text {
                font-size: 0.9rem;
                color: $uniqueColor;
                text-align: center;
                @include setPadding(0 0 5px);
            }
        }
    }
}



@media screen and (min-width: 1400px) and (max-width:1920px){
    .footer {
        background-color: $mainColor;
        @include setPadding(50px 100px 35px);
        @include setFlex(flex, row, space-between, flex-start);
    
        .footer-contacts {
            @include setFlex(flex, column, center, flex-start);
            @include setPadding(0);
    
            .phone {
                color: $uniqueColor;
                font-size: 0.9rem;
                transition: color 0.3s ease;
                @include setMargin(0 0 10px);
    
                .phone-text {
                    @include setMargin(0 0 0 10px);
                }
    
                &:hover {
                    color: $primaryColor;
                }
            }
        }
    
        .footer-info {
            @include setFlex(flex, column, flex-end, flex-end);
    
            .info-text {
                font-size: 0.9rem;
                color: $uniqueColor;
                text-align: center;
                @include setPadding(0 0 10px);
            }
        }
    }
}