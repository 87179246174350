@import './../tools.scss';

@media screen and (min-width: 375px) and (max-width:575px){
    .projects-wrapper {
        width: $sizePercent;
        @include setPadding(50px 20px 0);
        @include setFlex(flex, row, space-between, center);
        flex-wrap: wrap;
    
        .projects-title {
            width: $sizePercent;
            font-size: 1.5rem;
            font-family: "Closer Mint type Semibold";
            text-transform: uppercase;
            @include setPadding(40px 20px 20px 40px);
            @include position(relative);
    
            &:before {
                content: "";
                @include position(absolute);
                top: 55px;
                left: 0;
                width: 25px;
                height: 2px;
                background-color: $primaryColor;
            }
        }
    
        .projects-list{
            width: $sizePercent;
            @include setFlex(flex, column, space-between, center);
            flex-wrap: wrap;
        }
    
        .project-item {
            width: 100%;
            @include setMargin(0 0 50px);
            @include setFlex(flex, column, center, center);
    
            .project-icon{
                width: $sizePercent;
                height: 200px;
    
                .project-img{
                    width: $sizePercent;
                    height: $sizePercent;
                    object-fit: cover;
                }
            }
    
            .works-item-content {
                width: $sizePercent;
                @include setPadding(0 20px 10px);
                background-color: rgb(243, 243, 243);
    
                .text-title {
                    @include setPadding(20px 0);
                    font-size: 17px;
                    text-align: center;
                    text-transform: uppercase;
                    font-family: "Closer Mint type Bold";
                    border-bottom: 2px solid $primaryColor;
                }
    
                .works-content {
    
                    @include setFlex(flex, column, space-between, flex-start);
                    @include setPadding(20px 0 0);
    
                    .text-item {
                        width: $sizePercent;
                        @include setMargin(0 0 20px);
    
                        .main-text {
                            font-weight: bold;
                            @include setMargin(0 0 10px);
                        }
                    }
    
                    .text-item--primary {
                        text-align: start;
                    }
                }
    
                .text-end {
                    width: $sizePercent;
                    @include setMargin(30px 0 0);
    
                    .main-text {
                        text-align: start;
                        font-family: "Closer Mint type Semibold";
                        @include setMargin(0 0 10px);
                    }
    
                    .secondary-text {
                        text-align: justify;
                    }
                }
            }
        }
    }
}


@media screen and (min-width: 576px) and (max-width:767px){
    .projects-wrapper {
        width: $sizePercent;
        @include setPadding(75px 20px);
        @include setFlex(flex, row, space-between, center);
        flex-wrap: wrap;
    
        .projects-title {
            width: $sizePercent;
            font-size: 1.5rem;
            font-family: "Closer Mint type Semibold";
            text-transform: uppercase;
            @include setPadding(40px 20px 40px 40px);
            @include position(relative);
    
            &:before {
                content: "";
                @include position(absolute);
                top: 55px;
                left: 0;
                width: 25px;
                height: 2px;
                background-color: $primaryColor;
            }
        }
    
        .projects-list{
            width: $sizePercent;
            @include setFlex(flex, column, space-between, center);
            flex-wrap: wrap;
        }
    
        .project-item {
            width: 100%;
            @include setMargin(0 0 50px);
            @include setFlex(flex, column, center, center);
    
            .project-icon{
                width: $sizePercent;
                height: 300px;
    
                .project-img{
                    width: $sizePercent;
                    height: $sizePercent;
                    object-fit: cover;
                }
            }
    
            .works-item-content {
                width: $sizePercent;
                @include setPadding(0 20px 10px);
                background-color: rgb(243, 243, 243);
    
                .text-title {
                    @include setPadding(30px 0);
                    font-size: 17px;
                    text-align: center;
                    text-transform: uppercase;
                    font-family: "Closer Mint type Bold";
                    border-bottom: 2px solid $primaryColor;
                }
    
                .works-content {
    
                    @include setFlex(flex, column, space-between, flex-start);
                    @include setPadding(20px 0 0);
    
                    .text-item {
                        width: $sizePercent;
                        @include setMargin(0 0 20px);
    
                        .main-text {
                            font-weight: bold;
                            @include setMargin(0 0 10px);
                        }
                    }
    
                    .text-item--primary {
                        text-align: start;
                    }
                }
    
                .text-end {
                    width: $sizePercent;
                    @include setMargin(30px 0 0);
    
                    .main-text {
                        text-align: start;
                        font-family: "Closer Mint type Semibold";
                        @include setMargin(0 0 10px);
                    }
    
                    .secondary-text {
                        text-align: justify;
                    }
                }
            }
        }
    }
}


@media screen and (min-width: 768px) and (max-width:991px){
    .projects-wrapper {
        width: $sizePercent;
        @include setPadding(100px 20px);
        @include setFlex(flex, row, space-between, center);
        flex-wrap: wrap;
    
        .projects-title {
            width: $sizePercent;
            font-size: 1.5rem;
            font-family: "Closer Mint type Semibold";
            text-transform: uppercase;
            @include setPadding(40px 20px 40px 40px);
            @include position(relative);
    
            &:before {
                content: "";
                @include position(absolute);
                top: 55px;
                left: 0;
                width: 25px;
                height: 2px;
                background-color: $primaryColor;
            }
        }
    
        .projects-list{
            width: $sizePercent;
            @include setFlex(flex, column, space-between, center);
            flex-wrap: wrap;
        }
    
        .project-item {
            width: 100%;
            @include setMargin(0 0 50px);
            @include setFlex(flex, column, center, center);
    
            .project-icon{
                width: $sizePercent;
                height: 350px;
    
                .project-img{
                    width: $sizePercent;
                    height: $sizePercent;
                    object-fit: cover;
                }
            }
    
            .works-item-content {
                width: $sizePercent;
                @include setPadding(0 20px 10px);
                background-color: rgb(243, 243, 243);
    
                .text-title {
                    @include setPadding(30px 0);
                    font-size: 17px;
                    text-align: center;
                    text-transform: uppercase;
                    font-family: "Closer Mint type Bold";
                    border-bottom: 2px solid $primaryColor;
                }
    
                .works-content {
    
                    @include setFlex(flex, row, space-between, flex-start);
                    @include setPadding(20px 0 0);
    
                    .text-item {
                        width: $sizePercent;
                        @include setMargin(0 0 20px);
    
                        .main-text {
                            font-weight: bold;
                            @include setMargin(0 0 10px);
                        }
                    }
    
                    .text-item--primary {
                        text-align: end;
                    }
                }
    
                .text-end {
                    width: $sizePercent;
                    @include setMargin(30px 0 0);
    
                    .main-text {
                        text-align: start;
                        font-family: "Closer Mint type Semibold";
                        @include setMargin(0 0 10px);
                    }
    
                    .secondary-text {
                        text-align: justify;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 992px) and (max-width:1199px){
    .projects-wrapper {
        width: $sizePercent;
        @include setPadding(100px 30px 0);
        @include setFlex(flex, row, space-between, center);
        flex-wrap: wrap;
    
        .projects-title {
            width: $sizePercent;
            font-size: 1.5rem;
            font-family: "Closer Mint type Semibold";
            text-transform: uppercase;
            @include setPadding(70px 20px 70px 40px);
            @include position(relative);
    
            &:before {
                content: "";
                @include position(absolute);
                top: 85px;
                left: 0;
                width: 25px;
                height: 2px;
                background-color: $primaryColor;
            }
        }
    
        .projects-list{
            width: $sizePercent;
            @include setFlex(flex, row, space-between, center);
            flex-wrap: wrap;
        }
    
        .project-item {
            width: 49%;
            @include setMargin(0 0 50px);
            @include setFlex(flex, column, center, center);
    
            .project-icon{
                width: $sizePercent;
                height: 400px;
    
                .project-img{
                    width: $sizePercent;
                    height: $sizePercent;
                    object-fit: cover;
                }
            }
    
            .works-item-content {
                width: $sizePercent;
                @include setPadding(0 20px 30px);
                background-color: rgb(243, 243, 243);
    
                .text-title {
                    @include setPadding(30px 0);
                    font-size: 17px;
                    text-align: center;
                    text-transform: uppercase;
                    font-family: "Closer Mint type Bold";
                    border-bottom: 2px solid $primaryColor;
                }
    
                .works-content {
    
                    @include setFlex(flex, row, space-between, flex-start);
                    @include setPadding(30px 20px 0);
    
                    .text-item {
                        width: $sizePercent;
                        @include setMargin(0 0 20px);
    
                        .main-text {
                            font-weight: bold;
                            @include setMargin(0 0 10px);
                        }
                    }
    
                    .text-item--primary {
                        text-align: end;
                        @include setPadding(0 0 0 20px);
                    }
                }
    
                .text-end {
                    width: $sizePercent;
                    @include setMargin(30px 0 0);
    
                    .main-text {
                        text-align: start;
                        font-family: "Closer Mint type Semibold";
                        @include setMargin(0 0 10px);
                    }
    
                    .secondary-text {
                        text-align: justify;
                    }
                }
            }
        }
    }
}


@media screen and (min-width: 1200px) and (max-width:1399px){
    .projects-wrapper {
        width: $sizePercent;
        @include setPadding(100px 30px 0);
        @include setFlex(flex, row, space-between, center);
        flex-wrap: wrap;
    
        .projects-title {
            width: $sizePercent;
            font-size: 1.5rem;
            font-family: "Closer Mint type Semibold";
            text-transform: uppercase;
            @include setPadding(70px 20px 70px 40px);
            @include position(relative);
    
            &:before {
                content: "";
                @include position(absolute);
                top: 85px;
                left: 0;
                width: 25px;
                height: 2px;
                background-color: $primaryColor;
            }
        }
    
        .projects-list{
            width: $sizePercent;
            @include setFlex(flex, row, space-between, center);
            flex-wrap: wrap;
        }
    
        .project-item {
            width: 48%;
            @include setMargin(0 0 50px);
            @include setFlex(flex, column, center, center);
    
            .project-icon{
                width: $sizePercent;
                height: 400px;
    
                .project-img{
                    width: $sizePercent;
                    height: $sizePercent;
                    object-fit: cover;
                }
            }
    
            .works-item-content {
                width: $sizePercent;
                @include setPadding(0 20px 30px);
                background-color: rgb(243, 243, 243);
    
                .text-title {
                    @include setPadding(30px 0);
                    font-size: 17px;
                    text-align: center;
                    text-transform: uppercase;
                    font-family: "Closer Mint type Bold";
                    border-bottom: 2px solid $primaryColor;
                }
    
                .works-content {
    
                    @include setFlex(flex, row, space-between, flex-start);
                    @include setPadding(30px 20px 0);
    
                    .text-item {
                        width: $sizePercent;
                        @include setMargin(0 0 20px);
    
                        .main-text {
                            font-weight: bold;
                            @include setMargin(0 0 10px);
                        }
                    }
    
                    .text-item--primary {
                        text-align: end;
                        @include setPadding(0 0 0 20px);
                    }
                }
    
                .text-end {
                    width: $sizePercent;
                    @include setMargin(30px 0 0);
    
                    .main-text {
                        text-align: start;
                        font-family: "Closer Mint type Semibold";
                        @include setMargin(0 0 10px);
                    }
    
                    .secondary-text {
                        text-align: justify;
                    }
                }
            }
        }
    }
}



@media screen and (min-width: 1400px) and (max-width:1920px){
    .projects-wrapper {
        width: $sizePercent;
        @include setPadding(100px 100px 0);
        @include setFlex(flex, row, space-between, center);
        flex-wrap: wrap;
    
        .projects-title {
            width: $sizePercent;
            font-size: 1.5rem;
            font-family: "Closer Mint type Semibold";
            text-transform: uppercase;
            @include setPadding(70px 20px 70px 40px);
            @include position(relative);
    
            &:before {
                content: "";
                @include position(absolute);
                top: 85px;
                left: 0;
                width: 25px;
                height: 2px;
                background-color: $primaryColor;
            }
        }
    
        .projects-list{
            width: $sizePercent;
            @include setFlex(flex, row, space-between, center);
            flex-wrap: wrap;
        }
    
        .project-item {
            width: 48%;
            @include setMargin(0 0 50px);
            @include setFlex(flex, column, center, center);
    
            .project-icon{
                width: $sizePercent;
                height: 400px;
    
                .project-img{
                    width: $sizePercent;
                    height: $sizePercent;
                    object-fit: cover;
                }
            }
    
            .works-item-content {
                width: $sizePercent;
                @include setPadding(0 20px 30px);
                background-color: rgb(243, 243, 243);
    
                .text-title {
                    @include setPadding(30px 0);
                    font-size: 17px;
                    text-align: center;
                    text-transform: uppercase;
                    font-family: "Closer Mint type Bold";
                    border-bottom: 2px solid $primaryColor;
                }
    
                .works-content {
    
                    @include setFlex(flex, row, space-between, flex-start);
                    @include setPadding(30px 20px 0);
    
                    .text-item {
                        width: $sizePercent;
                        @include setMargin(0 0 20px);
    
                        .main-text {
                            font-weight: bold;
                            @include setMargin(0 0 10px);
                        }
                    }
    
                    .text-item--primary {
                        text-align: end;
                        @include setPadding(0 0 0 20px);
                    }
                }
    
                .text-end {
                    width: $sizePercent;
                    @include setMargin(30px 0 0);
    
                    .main-text {
                        text-align: start;
                        font-family: "Closer Mint type Semibold";
                        @include setMargin(0 0 10px);
                    }
    
                    .secondary-text {
                        text-align: justify;
                    }
                }
            }
        }
    }
}





