$sizePercent: 100%;
$size: 100;
$mainColor: rgba(33, 37, 41); 
$mainColor2: rgba(33, 37, 41, 0.9); 
$primaryColor: #e3aa55; 
$secondaryColor: #fff;
$uniqueColor: #bbbbbb;
$titleFontSize: 2rem;
$textFontSize: 1.125rem;
$fontRegular: "Closer Mint type Regular";
$fontSemibold: "Closer Mint type Semibold";
$fontBold: "Closer Mint type Bold";


@mixin setFlex ($dispFlex, $flexDir, $jusCon, $alItem) {
    display: $dispFlex;
    flex-direction: $flexDir;
    justify-content: $jusCon;
    align-items: $alItem;
}

@mixin setMargin ($setM) {
    margin: $setM;
}

@mixin setPadding ($setP) {
    padding: $setP;
}

@mixin bor ($bor) {
    border: $bor;
}

@mixin borderR ($br) {
    border-radius: $br;
}

@mixin position ($pos) {
    position: $pos;
}

@mixin way ($top, $right, $bottom, $left) {
    top: $top;
    right: $right;
    bottom: $bottom;
    left: $left;
}

@font-face {
    font-family: "Closer Mint type Bold";
    src: url(./assets/fonts/MINT_TYPE_CLOSER_BOLD.OTF);
}

@font-face {
    font-family: "Closer Mint type Regular";
    src: url(./assets/fonts/MINT_TYPE_CLOSER_REGULAR.OTF);
}

@font-face {
    font-family: "Closer Mint type Semibold";
    src: url(./assets/fonts/MINT_TYPE_CLOSER_SEMIBOLD.OTF);
}