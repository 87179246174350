@import './../tools.scss';



@media screen and (min-width: 375px) and (max-width:575px){
    .about-wrapper{
        width: $sizePercent;
        @include setPadding(50px 20px 0);
    
        .about-title {
            width: $sizePercent;
            font-size: 1.5rem;
            font-family: "Closer Mint type Semibold";
            text-transform: uppercase;
            @include setPadding(70px 20px 40px 40px);
            @include position(relative);
    
            &:before {
                content: "";
                @include position(absolute);
                top: 85px;
                left: 0;
                width: 25px;
                height: 2px;
                background-color: $primaryColor;
            }
        }
    
        .about-desc-wrapper{
            width: $sizePercent;
            @include setFlex(flex, column, space-between, center);
            @include setPadding(0 0 25px);
            border-bottom: 1px solid $mainColor2;
    
            .about-desc-wrapper-image{
                width: 100%;
                height: 200px;
    
                .about-desc-wrapper-image-pic{
                    width: $sizePercent;
                    height: $sizePercent;
                    object-position: 0 75%;
                    object-fit: cover;
                }
            }
    
            .about-desc-wrapper-content{
                width: 100%;
                @include setPadding(25px 0);
    
                .article-about{
                    font-size: 0.9rem;
                    width: $sizePercent;
                    text-align: justify;
                    @include setMargin(0 0 20px);
                }
            }
        }
    
        .sections-wrapper{
            width: $sizePercent;
            @include setPadding(75px 0 25px);
            @include setFlex(flex, column, space-between, center);
    
            .service-item{
                width: 100%;
                @include setFlex(flex, column, center, center);
    
                .item-icon{
                    width: $sizePercent;
                    height: 200px;
    
                    .about-img{
                        width: $sizePercent;
                        height: $sizePercent;
                        object-fit: cover;
                    }
                }
    
                .item-text{
                    width: $sizePercent;
                    @include setPadding(35px 0);
    
                    .item-title{
                        @include setPadding(0 0 35px);
                        text-align: center;
                        font-size: 1.2rem;
                        font-weight: bold;
                        text-transform: uppercase;
                    }
    
                    .item-content{
                        width: $sizePercent;
                        text-align: justify;
                        font-size: 0.9rem;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 576px) and (max-width:767px){
    .about-wrapper{
        width: $sizePercent;
        @include setPadding(50px 20px 0);
    
        .about-title {
            width: $sizePercent;
            font-size: 1.5rem;
            font-family: "Closer Mint type Semibold";
            text-transform: uppercase;
            @include setPadding(70px 20px 40px 40px);
            @include position(relative);
    
            &:before {
                content: "";
                @include position(absolute);
                top: 85px;
                left: 0;
                width: 25px;
                height: 2px;
                background-color: $primaryColor;
            }
        }
    
        .about-desc-wrapper{
            width: $sizePercent;
            @include setFlex(flex, column, space-between, center);
            @include setPadding(0 0 25px);
            border-bottom: 1px solid $mainColor2;
    
            .about-desc-wrapper-image{
                width: 100%;
                height: 300px;
    
                .about-desc-wrapper-image-pic{
                    width: $sizePercent;
                    height: $sizePercent;
                    object-position: 0 75%;
                    object-fit: cover;
                }
            }
    
            .about-desc-wrapper-content{
                width: 100%;
                @include setPadding(25px 0);
    
                .article-about{
                    font-size: 1rem;
                    width: $sizePercent;
                    text-align: justify;
                    @include setMargin(0 0 20px);
                }
            }
        }
    
        .sections-wrapper{
            width: $sizePercent;
            @include setPadding(75px 0 25px);
            @include setFlex(flex, column, space-between, center);
    
            .service-item{
                width: 100%;
                @include setFlex(flex, column, center, center);
    
                .item-icon{
                    width: $sizePercent;
                    height: 300px;
    
                    .about-img{
                        width: $sizePercent;
                        height: $sizePercent;
                        object-fit: cover;
                    }
                }
    
                .item-text{
                    width: $sizePercent;
                    @include setPadding(35px 0);
    
                    .item-title{
                        @include setPadding(0 0 35px);
                        text-align: center;
                        font-size: 1.3rem;
                        font-weight: bold;
                        text-transform: uppercase;
                    }
    
                    .item-content{
                        width: $sizePercent;
                        text-align: justify;
                        font-size: 1rem;
                    }
                }
            }
        }
    }
}


@media screen and (min-width: 768px) and (max-width:991px){
    .about-wrapper{
        width: $sizePercent;
        @include setPadding(50px 20px 0);
    
        .about-title {
            width: $sizePercent;
            font-size: 1.5rem;
            font-family: "Closer Mint type Semibold";
            text-transform: uppercase;
            @include setPadding(70px 20px 40px 40px);
            @include position(relative);
    
            &:before {
                content: "";
                @include position(absolute);
                top: 85px;
                left: 0;
                width: 25px;
                height: 2px;
                background-color: $primaryColor;
            }
        }
    
        .about-desc-wrapper{
            width: $sizePercent;
            @include setFlex(flex, column, space-between, center);
            @include setPadding(0 0 25px);
            border-bottom: 1px solid $mainColor2;
    
            .about-desc-wrapper-image{
                width: 100%;
                height: 350px;
    
                .about-desc-wrapper-image-pic{
                    width: $sizePercent;
                    height: $sizePercent;
                    object-position: 0 75%;
                    object-fit: cover;
                }
            }
    
            .about-desc-wrapper-content{
                width: 100%;
                @include setPadding(25px 0);
    
                .article-about{
                    font-size: 1rem;
                    width: $sizePercent;
                    text-align: justify;
                    @include setMargin(0 0 20px);
                }
            }
        }
    
        .sections-wrapper{
            width: $sizePercent;
            @include setPadding(75px 0 25px);
            @include setFlex(flex, column, space-between, center);
    
            .service-item{
                width: 100%;
                @include setFlex(flex, column, center, center);
    
                .item-icon{
                    width: $sizePercent;
                    height: 300px;
    
                    .about-img{
                        width: $sizePercent;
                        height: $sizePercent;
                        object-fit: cover;
                    }
                }
    
                .item-text{
                    width: $sizePercent;
                    @include setPadding(35px 0);
    
                    .item-title{
                        @include setPadding(0 0 35px);
                        text-align: center;
                        font-size: 1.3rem;
                        font-weight: bold;
                        text-transform: uppercase;
                    }
    
                    .item-content{
                        width: $sizePercent;
                        text-align: justify;
                        font-size: 1rem;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 992px) and (max-width:1199px){
    .about-wrapper{
        width: $sizePercent;
        @include setPadding(100px 50px 0);
    
        .about-title {
            width: $sizePercent;
            font-size: 1.5rem;
            font-family: "Closer Mint type Semibold";
            text-transform: uppercase;
            @include setPadding(40px 20px 20px 40px);
            @include position(relative);
    
            &:before {
                content: "";
                @include position(absolute);
                top: 55px;
                left: 0;
                width: 25px;
                height: 2px;
                background-color: $primaryColor;
            }
        }
    
        .about-desc-wrapper{
            width: $sizePercent;
            @include setFlex(flex, row, space-between, center);
            @include setPadding(0 0 75px);
            border-bottom: 1px solid $mainColor2;
    
            .about-desc-wrapper-image{
                width: 48%;
                height: 400px;
    
                .about-desc-wrapper-image-pic{
                    width: $sizePercent;
                    height: $sizePercent;
                    object-position: 0 75%;
                    object-fit: cover;
                }
            }
    
            .about-desc-wrapper-content{
                width: 48%;
                @include setPadding(0 0 0 25px);
    
                .article-about{
                    width: $sizePercent;
                    text-align: justify;
                    @include setMargin(0 0 35px);
                }
            }
        }
    
        .sections-wrapper{
            width: $sizePercent;
            @include setPadding(75px 0 25px);
            @include setFlex(flex, column, space-between, center);
    
            .service-item{
                width: 100%;
                @include setFlex(flex, column, center, center);
    
                .item-icon{
                    width: $sizePercent;
                    height: 400px;
    
                    .about-img{
                        width: $sizePercent;
                        height: $sizePercent;
                        object-fit: cover;
                    }
                }
    
                .item-text{
                    width: $sizePercent;
                    @include setPadding(35px 0);
    
                    .item-title{
                        @include setPadding(0 0 35px);
                        text-align: center;
                        font-size: 1.3rem;
                        font-weight: bold;
                        text-transform: uppercase;
                    }
    
                    .item-content{
                        width: $sizePercent;
                        text-align: justify;
                    }
                }
            }
        }
    }
}


@media screen and (min-width: 1200px) and (max-width:1399px){
    .about-wrapper{
        width: $sizePercent;
        @include setPadding(100px 100px 0);
    
        .about-title {
            width: $sizePercent;
            font-size: 1.5rem;
            font-family: "Closer Mint type Semibold";
            text-transform: uppercase;
            @include setPadding(70px 20px 70px 40px);
            @include position(relative);
    
            &:before {
                content: "";
                @include position(absolute);
                top: 85px;
                left: 0;
                width: 25px;
                height: 2px;
                background-color: $primaryColor;
            }
        }
    
        .about-desc-wrapper{
            width: $sizePercent;
            @include setFlex(flex, row, space-between, center);
            @include setPadding(0 0 75px);
            border-bottom: 1px solid $mainColor2;
    
            .about-desc-wrapper-image{
                width: 47%;
                height: 400px;
    
                .about-desc-wrapper-image-pic{
                    width: $sizePercent;
                    height: $sizePercent;
                    object-position: 0 75%;
                    object-fit: cover;
                }
            }
    
            .about-desc-wrapper-content{
                width: 48%;
                @include setPadding(25px);
    
                .article-about{
                    width: $sizePercent;
                    text-align: justify;
                    @include setMargin(0 0 35px);
                }
            }
        }
    
        .sections-wrapper{
            width: $sizePercent;
            @include setPadding(75px 0);
            @include setFlex(flex, row, space-between, center);
    
            .service-item{
                width: 27%;
                @include setFlex(flex, column, center, center);
    
                .item-icon{
                    width: $sizePercent;
                    height: 200px;
    
                    .about-img{
                        width: $sizePercent;
                        height: $sizePercent;
                        object-fit: cover;
                    }
                }
    
                .item-text{
                    width: $sizePercent;
                    @include setPadding(35px 0);
    
                    .item-title{
                        @include setPadding(0 0 35px);
                        text-align: center;
                        font-size: 1.3rem;
                        font-weight: bold;
                        text-transform: uppercase;
                    }
    
                    .item-content{
                        width: $sizePercent;
                        text-align: justify;
                    }
                }
            }
        }
    }
}



@media screen and (min-width: 1400px) and (max-width:1920px){
    .about-wrapper{
        width: $sizePercent;
        @include setPadding(100px 50px 0);
    
        .about-title {
            width: $sizePercent;
            font-size: 1.5rem;
            font-family: "Closer Mint type Semibold";
            text-transform: uppercase;
            @include setPadding(70px 20px 70px 40px);
            @include position(relative);
    
            &:before {
                content: "";
                @include position(absolute);
                top: 85px;
                left: 0;
                width: 25px;
                height: 2px;
                background-color: $primaryColor;
            }
        }
    
        .about-desc-wrapper{
            width: $sizePercent;
            @include setFlex(flex, row, space-between, center);
            @include setPadding(0 0 75px);
            border-bottom: 1px solid $mainColor2;
    
            .about-desc-wrapper-image{
                width: 47%;
                height: 400px;
    
                .about-desc-wrapper-image-pic{
                    width: $sizePercent;
                    height: $sizePercent;
                    object-position: 0 75%;
                    object-fit: cover;
                }
            }
    
            .about-desc-wrapper-content{
                width: 48%;
                @include setPadding(25px);
    
                .article-about{
                    width: $sizePercent;
                    text-align: justify;
                    @include setMargin(0 0 35px);
                }
            }
        }
    
        .sections-wrapper{
            width: $sizePercent;
            @include setPadding(75px 0 25px);
            @include setFlex(flex, row, space-between, center);
    
            .service-item{
                width: 27%;
                @include setFlex(flex, column, center, center);
    
                .item-icon{
                    width: $sizePercent;
                    height: 200px;
    
                    .about-img{
                        width: $sizePercent;
                        height: $sizePercent;
                        object-fit: cover;
                    }
                }
    
                .item-text{
                    width: $sizePercent;
                    @include setPadding(35px 0);
    
                    .item-title{
                        @include setPadding(0 0 35px);
                        text-align: center;
                        font-size: 1.3rem;
                        font-weight: bold;
                        text-transform: uppercase;
                    }
    
                    .item-content{
                        width: $sizePercent;
                        text-align: justify;
                    }
                }
            }
        }
    }
}