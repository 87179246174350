@import './../tools.scss';


@media screen and (min-width: 375px) and (max-width:575px) {
    .home-wrapper {
        width: $sizePercent;
    
        .screen {
            width: 100%;
            height: 100vh;
            background-image: url('./../assets/images/cover.jpg');
            background-size: cover;
            background-position: 0 0;
            @include position(relative);
            @include setPadding(0 20px 75px);
            @include setFlex(flex, column, flex-end, center);
    
            .title-wrapper {
                @include setFlex(flex, column, center, center);
    
                .screen-title {
                    // display: inline-block;
                    color: $secondaryColor;
                    font-size: 2.3rem;
                    text-transform: uppercase;
                }
    
                .word {
                    font-weight: bold;
                    font-size: 2.3rem;
                    color: $primaryColor;
                    text-transform: uppercase;
                    opacity: 1;
                    transition: opacity 1s ease-in-out;
                }
            }
    
            .screen-undertitle {
                text-align: center;
                @include setMargin(30px 0 0);
                @include setPadding(0 20px);
                color: $secondaryColor;
                font-size: 1rem;
            }
        }
    
        .perks {
            width: $sizePercent;
            @include setPadding(50px 20px);
            @include setFlex(flex, column, space-between, center);
    
            .perks-item {
                width: 100%;
                @include setMargin(0 0 50px);
    
                .icon-item {
                    width: 100px;
                    height: 100px;
                    @include borderR(50px);
                    border: 2px solid $mainColor;
                    @include setFlex(flex, row, center, center);
                    @include setMargin(0 auto 35px);
    
                    .perks-icon {
                        font-size: 2rem;
                        color: $primaryColor;
                    }
                }
    
                .item-title {
                    width: $sizePercent;
                    text-align: center;
                    @include setMargin(0 auto 35px);
                    font-size: 1.2rem;
                    text-transform: uppercase;
                    font-weight: bold;
                }
    
                .item-content {
                    text-align: center;
                }
            }
        }
    
        .description {
            width: $sizePercent;
            height: $size * 5 + px;
            background-image: url('../assets/images/bg.jpg');
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center bottom;
            background-attachment: fixed;
            @include position(relative);
            @include setFlex(flex, row, center, center);
    
            .description-text {
                color: $secondaryColor;
                font-size: 1.2rem;
                text-align: center;
                width: 60%;
            }
        }
    
        .services {
            @include setPadding(0 20px 50px);
    
            .services-title {
                width: $sizePercent;
                font-size: 1.3rem;
                font-family: "Closer Mint type Semibold";
                text-transform: uppercase;
                @include setPadding(50px);
                @include position(relative);
                text-align: center;
            }
    
            .services-wrapper {
                width: $sizePercent;
                @include setFlex(flex, column, space-between, flex-start);
                flex-wrap: wrap;
    
                .services-item {
                    width: 100%;
                    overflow: hidden;
                    transition: 0.3s ease-out;
                    @include setFlex(flex, column, flex-start, flex-start);
                    height: $size * 3 + px;
                    @include setMargin(0 0 50px);
                    @include position(relative);
    
                    &:hover .services-text {
                        background-color: rgba(33, 37, 41, 0.9);
                        width: $sizePercent;
                        left: 0;
                        display: block;
                    }
        
                    &:hover .item-service-title {
                        display: none;
                    }
    
                    .services-image-commercial,
                    .services-image-workspace,
                    .services-image-interior {
                        width: $sizePercent;
                        height: $sizePercent;
                        background-image: url(../assets/images/services-item-01.jpg);
                        background-size: cover;
                        background-position-y: bottom;
                        @include setFlex(flex, row, center, flex-end);
                        @include setPadding(25px);
    
                        .item-service-title {
                            text-align: center;
                            @include setPadding(0 0 10px);
                            font-size: 1.2rem;
                            text-transform: uppercase;
                            color: $secondaryColor;
                            font-weight: bold;
                        }
                    }
    
                    .services-image-workspace {
                        background-image: url(../assets/images/services-item-02.jpg);
                    }
    
                    .services-image-interior {
                        background-image: url(../assets/images/services-item-03.jpg);
                    }
    
                    .services-text {
                        display: none;
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        text-align: center;
    
                        .item-article {
                            position: absolute;
                            top: 34%;
                            left: 0;
                            @include setPadding(0 40px);
                            width: $sizePercent;
                            color: $secondaryColor;
                            animation: entry 0.7s;
                        }
    
                        @keyframes entry {
                            from {
                                top: 150%;
                            }
    
                            to {
                                top: 34%;
                            }
                        }
                    }
    
                    .services-image-works {
                        width: $sizePercent;
                        height: $sizePercent;
                        background-color: black;
                        @include position(relative);
                        @include setFlex(flex, row, center, center);
    
                        .grid__link {
                            width: $sizePercent;
                            height: $sizePercent;
                            @include setMargin(0 auto);
                            @include setFlex(flex, row, center, center);
                            text-decoration: none;
                            text-transform: uppercase;
                            color: $secondaryColor;
                            font-family: $fontBold;
                            font-size: $textFontSize;
                            transition: 0.5s ease-out;
                            line-height: 1.5;
    
                            &:hover {
                              transform: scale(1.25);
                              background-color: $mainColor;
                              color: $primaryColor;
                              @include bor(3px solid $mainColor);
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 576px) and (max-width: 767px) {
    .home-wrapper {
        width: $sizePercent;
    
        .screen {
            width: 100%;
            height: 100vh;
            background-image: url('./../assets/images/cover.jpg');
            background-size: cover;
            background-position: 0 0;
            @include position(relative);
            @include setPadding(0 20px 150px);
            @include setFlex(flex, column, flex-end, center);
    
            .title-wrapper {
                @include setFlex(flex, column, center, center);
    
                .screen-title {
                    // display: inline-block;
                    color: $secondaryColor;
                    font-size: 2.5rem;
                    text-transform: uppercase;
                }
    
                .word {
                    font-weight: bold;
                    font-size: 2.5rem;
                    color: $primaryColor;
                    text-transform: uppercase;
                    opacity: 1;
                    transition: opacity 1s ease-in-out;
                }
            }
    
            .screen-undertitle {
                text-align: center;
                @include setMargin(30px 0 0);
                @include setPadding(0 20px);
                color: $secondaryColor;
                font-size: 1.5rem;
            }
        }
    
        .perks {
            width: $sizePercent;
            @include setPadding(50px 20px);
            @include setFlex(flex, column, space-between, center);
    
            .perks-item {
                width: 100%;
                @include setMargin(0 0 50px);
    
                .icon-item {
                    width: 100px;
                    height: 100px;
                    @include borderR(50px);
                    border: 2px solid $mainColor;
                    @include setFlex(flex, row, center, center);
                    @include setMargin(0 auto 35px);
    
                    .perks-icon {
                        font-size: 2rem;
                        color: $primaryColor;
                    }
                }
    
                .item-title {
                    width: $sizePercent;
                    text-align: center;
                    @include setMargin(0 auto 35px);
                    font-size: 1.2rem;
                    text-transform: uppercase;
                    font-weight: bold;
                }
    
                .item-content {
                    text-align: center;
                }
            }
        }
    
        .description {
            width: $sizePercent;
            height: $size * 5 + px;
            background-image: url('../assets/images/bg.jpg');
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center bottom;
            background-attachment: fixed;
            @include position(relative);
            @include setFlex(flex, row, center, center);
    
            .description-text {
                color: $secondaryColor;
                font-size: 1.2rem;
                text-align: center;
                width: 60%;
            }
        }
    
        .services {
            @include setPadding(0 20px 50px);
    
            .services-title {
                width: $sizePercent;
                font-size: 1.3rem;
                font-family: "Closer Mint type Semibold";
                text-transform: uppercase;
                @include setPadding(50px);
                @include position(relative);
                text-align: center;
            }
    
            .services-wrapper {
                width: $sizePercent;
                @include setFlex(flex, column, space-between, flex-start);
                flex-wrap: wrap;
    
                .services-item {
                    width: 100%;
                    overflow: hidden;
                    transition: 0.3s ease-out;
                    @include setFlex(flex, column, flex-start, flex-start);
                    height: $size * 3 + px;
                    @include setMargin(0 0 50px);
                    @include position(relative);
    
                    &:hover .services-text {
                        background-color: rgba(33, 37, 41, 0.9);
                        width: $sizePercent;
                        left: 0;
                        display: block;
                    }
        
                    &:hover .item-service-title {
                        display: none;
                    }
    
                    .services-image-commercial,
                    .services-image-workspace,
                    .services-image-interior {
                        width: $sizePercent;
                        height: $sizePercent;
                        background-image: url(../assets/images/services-item-01.jpg);
                        background-size: cover;
                        background-position-y: bottom;
                        @include setFlex(flex, row, center, flex-end);
                        @include setPadding(25px);
    
                        .item-service-title {
                            text-align: center;
                            @include setPadding(0 0 10px);
                            font-size: 1.2rem;
                            text-transform: uppercase;
                            color: $secondaryColor;
                            font-weight: bold;
                        }
                    }
    
                    .services-image-workspace {
                        background-image: url(../assets/images/services-item-02.jpg);
                    }
    
                    .services-image-interior {
                        background-image: url(../assets/images/services-item-03.jpg);
                    }
    
                    .services-text {
                        display: none;
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        text-align: center;
    
                        .item-article {
                            position: absolute;
                            top: 40%;
                            left: 0;
                            @include setPadding(0 40px);
                            width: $sizePercent;
                            color: $secondaryColor;
                            animation: entry 0.7s;
                        }
    
                        @keyframes entry {
                            from {
                                top: 150%;
                            }
    
                            to {
                                top: 40%;
                            }
                        }
                    }
    
                    .services-image-works {
                        width: $sizePercent;
                        height: $sizePercent;
                        background-color: black;
                        @include position(relative);
                        @include setFlex(flex, row, center, center);
    
                        .grid__link {
                            width: $sizePercent;
                            height: $sizePercent;
                            @include setMargin(0 auto);
                            @include setFlex(flex, row, center, center);
                            text-decoration: none;
                            text-transform: uppercase;
                            color: $secondaryColor;
                            font-family: $fontBold;
                            font-size: $textFontSize;
                            transition: 0.5s ease-out;
                            line-height: 1.5;
    
                            &:hover {
                              transform: scale(1.25);
                              background-color: $mainColor;
                              color: $primaryColor;
                              @include bor(3px solid $mainColor);
                            }
                        }
                    }
                }
            }
        }
    }
}


@media screen and (min-width: 768px) and (max-width: 991px) {
    .home-wrapper {
        width: $sizePercent;
    
        .screen {
            width: 100%;
            height: 100vh;
            background-image: url('./../assets/images/cover.jpg');
            background-size: cover;
            background-position: 0 0;
            @include position(relative);
            @include setPadding(0 20px 150px);
            @include setFlex(flex, column, flex-end, center);
    
            .title-wrapper {
                @include setFlex(flex, column, center, center);
    
                .screen-title {
                    // display: inline-block;
                    color: $secondaryColor;
                    font-size: 2.9rem;
                    text-transform: uppercase;
                }
    
                .word {
                    font-weight: bold;
                    font-size: 2.9rem;
                    color: $primaryColor;
                    text-transform: uppercase;
                    opacity: 1;
                    transition: opacity 1s ease-in-out;
                }
            }
    
            .screen-undertitle {
                text-align: center;
                @include setMargin(30px 0 0);
                @include setPadding(0 50px);
                color: $secondaryColor;
                font-size: 1.8rem;
            }
        }
    
        .perks {
            width: $sizePercent;
            @include setPadding(50px 20px);
            @include setFlex(flex, column, space-between, center);
    
            .perks-item {
                width: 100%;
                @include setMargin(0 0 50px);
    
                .icon-item {
                    width: 100px;
                    height: 100px;
                    @include borderR(50px);
                    border: 2px solid $mainColor;
                    @include setFlex(flex, row, center, center);
                    @include setMargin(0 auto 35px);
    
                    .perks-icon {
                        font-size: 2rem;
                        color: $primaryColor;
                    }
                }
    
                .item-title {
                    width: $sizePercent;
                    text-align: center;
                    @include setMargin(0 auto 35px);
                    font-size: 1.2rem;
                    text-transform: uppercase;
                    font-weight: bold;
                }
    
                .item-content {
                    text-align: center;
                    @include setPadding(0 50px);
                }
            }
        }
    
        .description {
            width: $sizePercent;
            height: $size * 5 + px;
            background-image: url('../assets/images/bg.jpg');
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center bottom;
            background-attachment: fixed;
            @include position(relative);
            @include setFlex(flex, row, center, center);
    
            .description-text {
                color: $secondaryColor;
                font-size: 1.2rem;
                text-align: center;
                width: 60%;
            }
        }
    
        .services {
            @include setPadding(0 20px 50px);
    
            .services-title {
                width: $sizePercent;
                font-size: 1.3rem;
                font-family: "Closer Mint type Semibold";
                text-transform: uppercase;
                @include setPadding(50px);
                @include position(relative);
                text-align: center;
            }
    
            .services-wrapper {
                width: $sizePercent;
                @include setFlex(flex, column, space-between, flex-start);
                flex-wrap: wrap;
    
                .services-item {
                    width: 100%;
                    overflow: hidden;
                    transition: 0.3s ease-out;
                    @include setFlex(flex, column, flex-start, flex-start);
                    height: $size * 3.5 + px;
                    @include setMargin(0 0 50px);
                    @include position(relative);
    
                    &:hover .services-text {
                        background-color: rgba(33, 37, 41, 0.9);
                        width: $sizePercent;
                        left: 0;
                        display: block;
                    }
        
                    &:hover .item-service-title {
                        display: none;
                    }
    
                    .services-image-commercial,
                    .services-image-workspace,
                    .services-image-interior {
                        width: $sizePercent;
                        height: $sizePercent;
                        background-image: url(../assets/images/services-item-01.jpg);
                        background-size: cover;
                        background-position-y: bottom;
                        @include setFlex(flex, row, center, flex-end);
                        @include setPadding(25px);
    
                        .item-service-title {
                            text-align: center;
                            @include setPadding(0 0 10px);
                            font-size: 1.5rem;
                            text-transform: uppercase;
                            color: $secondaryColor;
                            font-weight: bold;
                        }
                    }
    
                    .services-image-workspace {
                        background-image: url(../assets/images/services-item-02.jpg);
                    }
    
                    .services-image-interior {
                        background-image: url(../assets/images/services-item-03.jpg);
                    }
    
                    .services-text {
                        display: none;
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        text-align: center;
    
                        .item-article {
                            position: absolute;
                            top: 40%;
                            left: 0;
                            @include setPadding(0 40px);
                            width: $sizePercent;
                            color: $secondaryColor;
                            animation: entry 0.7s;
                        }
    
                        @keyframes entry {
                            from {
                                top: 150%;
                            }
    
                            to {
                                top: 40%;
                            }
                        }
                    }
    
                    .services-image-works {
                        width: $sizePercent;
                        height: $sizePercent;
                        background-color: black;
                        @include position(relative);
                        @include setFlex(flex, row, center, center);
    
                        .grid__link {
                            width: $sizePercent;
                            height: $sizePercent;
                            @include setMargin(0 auto);
                            @include setFlex(flex, row, center, center);
                            text-decoration: none;
                            text-transform: uppercase;
                            color: $secondaryColor;
                            font-family: $fontBold;
                            font-size: $textFontSize;
                            transition: 0.5s ease-out;
                            line-height: 1.5;
    
                            &:hover {
                              transform: scale(1.25);
                              background-color: $mainColor;
                              color: $primaryColor;
                              @include bor(3px solid $mainColor);
                            }
                        }
                    }
                }
            }
        }
    }
}



@media screen and (min-width: 992px) and (max-width: 1199px) {
    .home-wrapper {
        width: $sizePercent;
    
        .screen {
            width: 100%;
            height: 100vh;
            background-image: url('./../assets/images/cover.jpg');
            background-size: cover;
            background-position: 0 0;
            @include position(relative);
            @include setPadding(0 100px 150px);
            @include setFlex(flex, column, flex-end, flex-start);
    
            .title-wrapper {
                @include setFlex(flex, row, center, center);
    
                .screen-title {
                    display: inline-block;
                    color: $secondaryColor;
                    font-size: 3rem;
                    text-transform: uppercase;
                }
    
                .word {
                    font-weight: bold;
                    font-size: 3rem;
                    color: $primaryColor;
                    text-transform: uppercase;
                    opacity: 1;
                    transition: opacity 1s ease-in-out;
                    @include setMargin(0 0 0 15px);
                }
            }
    
            .screen-undertitle {
                @include setMargin(10px 0 0);
                color: $secondaryColor;
                font-size: 1.5rem;
            }
        }
    
        .perks {
            width: $sizePercent;
            @include setPadding(100px);
            @include setFlex(flex, column, space-between, flex-start);
    
            .perks-item {
                width: 100%;
                @include setMargin(0 0 50px);
    
                .icon-item {
                    width: 100px;
                    height: 100px;
                    @include borderR(50px);
                    border: 2px solid $mainColor;
                    @include setFlex(flex, row, center, center);
                    @include setMargin(0 auto 35px);
    
                    .perks-icon {
                        font-size: 2rem;
                        color: $primaryColor;
                    }
                }
    
                .item-title {
                    width: $sizePercent;
                    text-align: center;
                    @include setMargin(0 auto 35px);
                    font-size: 1.2rem;
                    text-transform: uppercase;
                    font-weight: bold;
                }
    
                .item-content {
                    text-align: center;
                }
            }
        }
    
        .description {
            width: $sizePercent;
            height: $size * 5 + px;
            background-image: url('../assets/images/bg.jpg');
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center bottom;
            background-attachment: fixed;
            @include position(relative);
            @include setFlex(flex, row, center, center);
    
            .description-text {
                color: $secondaryColor;
                font-size: 1.2rem;
                text-align: center;
                width: 60%;
            }
        }
    
        .services {
            @include setPadding(0 50px 50px);
    
            .services-title {
                width: $sizePercent;
                font-size: 1.5rem;
                font-family: "Closer Mint type Semibold";
                text-transform: uppercase;
                @include setPadding(70px 20px 70px 40px);
                @include position(relative);
    
                &:before {
                    content: "";
                    @include position(absolute);
                    top: 85px;
                    left: 0;
                    width: $size/4 + px;
                    height: $size/50 + px;
                    background-color: $primaryColor;
                }
            }
    
            .services-wrapper {
                width: $sizePercent;
                @include setFlex(flex, row, space-between, flex-start);
                flex-wrap: wrap;
    
                .services-item {
                    width: 48%;
                    overflow: hidden;
                    transition: 0.3s ease-out;
                    @include setFlex(flex, column, flex-start, flex-start);
                    height: $size * 3.5 + px;
                    @include setMargin(0 0 40px);
                    @include position(relative);
    
                    &:hover .services-text {
                        background-color: rgba(33, 37, 41, 0.9);
                        width: $sizePercent;
                        left: 0;
                        display: block;
                    }
        
                    &:hover .item-service-title {
                        display: none;
                    }
    
                    .services-image-commercial,
                    .services-image-workspace,
                    .services-image-interior {
                        width: $sizePercent;
                        height: $sizePercent;
                        background-image: url(../assets/images/services-item-01.jpg);
                        background-size: cover;
                        background-position-y: bottom;
                        @include setFlex(flex, row, center, flex-end);
    
                        .item-service-title {
                            @include setPadding(0 35px 35px);
                            font-size: 1.2rem;
                            text-transform: uppercase;
                            color: $secondaryColor;
                            font-weight: bold;
                            text-align: center;
                        }
                    }
    
                    .services-image-workspace {
                        background-image: url(../assets/images/services-item-02.jpg);
                    }
    
                    .services-image-interior {
                        background-image: url(../assets/images/services-item-03.jpg);
                    }
    
                    .services-text {
                        display: none;
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        text-align: center;
    
                        .item-article {
                            position: absolute;
                            top: 44%;
                            left: 0;
                            @include setPadding(0 40px);
                            width: $sizePercent;
                            color: $secondaryColor;
                            animation: entry 0.7s;
                        }
    
                        @keyframes entry {
                            from {
                                top: 150%;
                            }
    
                            to {
                                top: 44%;
                            }
                        }
                    }
    
                    .services-image-works {
                        width: $sizePercent;
                        height: $sizePercent;
                        background-color: black;
                        @include position(relative);
                        @include setFlex(flex, row, center, center);
    
                        .grid__link {
                            width: $sizePercent;
                            height: $sizePercent;
                            @include setMargin(0 auto);
                            @include setFlex(flex, row, center, center);
                            text-decoration: none;
                            text-transform: uppercase;
                            color: $secondaryColor;
                            font-family: $fontBold;
                            font-size: $textFontSize;
                            transition: 0.5s ease-out;
                            line-height: 1.5;
    
                            &:hover {
                              transform: scale(1.25);
                              background-color: $mainColor;
                              color: $primaryColor;
                              @include bor(3px solid $mainColor);
                            }
                        }
                    }
                }
            }
        }
    }
}


@media screen and (min-width: 1200px) and (max-width: 1399px) {
    .home-wrapper {
        width: $sizePercent;
    
        .screen {
            width: 100%;
            height: 100vh;
            background-image: url('./../assets/images/cover.jpg');
            background-size: cover;
            background-position: 0 0;
            @include position(relative);
            @include setPadding(0 100px 150px);
            @include setFlex(flex, column, flex-end, flex-start);
    
            .title-wrapper {
                @include setFlex(flex, row, center, center);
    
                .screen-title {
                    display: inline-block;
                    color: $secondaryColor;
                    font-size: 3rem;
                    text-transform: uppercase;
                }
    
                .word {
                    font-weight: bold;
                    font-size: 3rem;
                    color: $primaryColor;
                    text-transform: uppercase;
                    opacity: 1;
                    transition: opacity 1s ease-in-out;
                    @include setMargin(0 0 0 15px);
                }
            }
    
            .screen-undertitle {
                @include setMargin(10px 0 0);
                color: $secondaryColor;
                font-size: 1.5rem;
            }
        }
    
        .perks {
            width: $sizePercent;
            @include setPadding(100px 50px 30px);
            @include setFlex(flex, row, space-between, flex-start);
    
            .perks-item {
                width: 29%;
                @include setMargin(0 0 50px);
    
                .icon-item {
                    width: 100px;
                    height: 100px;
                    @include borderR(50px);
                    border: 2px solid $mainColor;
                    @include setFlex(flex, row, center, center);
                    @include setMargin(0 auto 35px);
    
                    .perks-icon {
                        font-size: 2rem;
                        color: $primaryColor;
                    }
                }
    
                .item-title {
                    width: $sizePercent;
                    text-align: center;
                    @include setMargin(0 auto 35px);
                    font-size: 1.2rem;
                    text-transform: uppercase;
                    font-weight: bold;
                }
    
                .item-content {
                    text-align: center;
                }
            }
        }
    
        .description {
            width: $sizePercent;
            height: $size * 5 + px;
            background-image: url('../assets/images/bg.jpg');
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center bottom;
            background-attachment: fixed;
            @include position(relative);
            @include setFlex(flex, row, center, center);
    
            .description-text {
                color: $secondaryColor;
                font-size: 1.2rem;
                text-align: center;
                width: 60%;
            }
        }
    
        .services {
            @include setPadding(0 50px 50px);
    
            .services-title {
                width: $sizePercent;
                font-size: 1.5rem;
                font-family: "Closer Mint type Semibold";
                text-transform: uppercase;
                @include setPadding(70px 20px 70px 40px);
                @include position(relative);
    
                &:before {
                    content: "";
                    @include position(absolute);
                    top: 85px;
                    left: 0;
                    width: $size/4 + px;
                    height: $size/50 + px;
                    background-color: $primaryColor;
                }
            }
    
            .services-wrapper {
                width: $sizePercent;
                @include setFlex(flex, row, space-between, flex-start);
                flex-wrap: wrap;
    
                .services-item {
                    width: 48%;
                    overflow: hidden;
                    transition: 0.3s ease-out;
                    @include setFlex(flex, column, flex-start, flex-start);
                    height: $size * 3.5 + px;
                    @include setMargin(0 0 40px);
                    @include position(relative);
    
                    &:hover .services-text {
                        background-color: rgba(33, 37, 41, 0.9);
                        width: $sizePercent;
                        left: 0;
                        display: block;
                    }
        
                    &:hover .item-service-title {
                        display: none;
                    }
    
                    .services-image-commercial,
                    .services-image-workspace,
                    .services-image-interior {
                        width: $sizePercent;
                        height: $sizePercent;
                        background-image: url(../assets/images/services-item-01.jpg);
                        background-size: cover;
                        background-position-y: bottom;
                        @include setFlex(flex, row, center, flex-end);
    
                        .item-service-title {
                            @include setPadding(0 35px 35px);
                            font-size: 1.2rem;
                            text-transform: uppercase;
                            color: $secondaryColor;
                            font-weight: bold;
                            text-align: center;
                        }
                    }
    
                    .services-image-workspace {
                        background-image: url(../assets/images/services-item-02.jpg);
                    }
    
                    .services-image-interior {
                        background-image: url(../assets/images/services-item-03.jpg);
                    }
    
                    .services-text {
                        display: none;
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        text-align: center;
    
                        .item-article {
                            position: absolute;
                            top: 44%;
                            left: 0;
                            @include setPadding(0 40px);
                            width: $sizePercent;
                            color: $secondaryColor;
                            animation: entry 0.7s;
                        }
    
                        @keyframes entry {
                            from {
                                top: 150%;
                            }
    
                            to {
                                top: 44%;
                            }
                        }
                    }
    
                    .services-image-works {
                        width: $sizePercent;
                        height: $sizePercent;
                        background-color: black;
                        @include position(relative);
                        @include setFlex(flex, row, center, center);
    
                        .grid__link {
                            width: $sizePercent;
                            height: $sizePercent;
                            @include setMargin(0 auto);
                            @include setFlex(flex, row, center, center);
                            text-decoration: none;
                            text-transform: uppercase;
                            color: $secondaryColor;
                            font-family: $fontBold;
                            font-size: $textFontSize;
                            transition: 0.5s ease-out;
                            line-height: 1.5;
    
                            &:hover {
                              transform: scale(1.25);
                              background-color: $mainColor;
                              color: $primaryColor;
                              @include bor(3px solid $mainColor);
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 1400px) and (max-width:1920px) {
    .home-wrapper {
        width: $sizePercent;
    
        .screen {
            width: 100%;
            height: 100vh;
            background-image: url('./../assets/images/cover.jpg');
            background-size: cover;
            background-position: 0 0;
            @include position(relative);
            @include setPadding(0 100px 150px);
            @include setFlex(flex, column, flex-end, flex-start);
    
            .title-wrapper {
                @include setFlex(flex, row, center, center);
    
                .screen-title {
                    display: inline-block;
                    color: $secondaryColor;
                    font-size: 4rem;
                    text-transform: uppercase;
                }
    
                .word {
                    font-weight: bold;
                    font-size: 4rem;
                    color: $primaryColor;
                    text-transform: uppercase;
                    opacity: 1;
                    transition: opacity 1s ease-in-out;
                    @include setMargin(0 0 0 15px);
                }
            }
    
            .screen-undertitle {
                @include setMargin(10px 0 0);
                color: $secondaryColor;
                font-size: 1.5rem;
            }
        }
    
        .perks {
            width: $sizePercent;
            @include setPadding(100px);
            @include setFlex(flex, row, space-between, flex-start);
    
            .perks-item {
                width: 27%;
    
                .icon-item {
                    width: 100px;
                    height: 100px;
                    @include borderR(50px);
                    border: 2px solid $mainColor;
                    @include setFlex(flex, row, center, center);
                    @include setMargin(0 auto 35px);
    
                    .perks-icon {
                        font-size: 2rem;
                        color: $primaryColor;
                    }
                }
    
                .item-title {
                    width: $sizePercent;
                    text-align: center;
                    @include setMargin(0 auto 35px);
                    font-size: 1.2rem;
                    text-transform: uppercase;
                    font-weight: bold;
                }
    
                .item-content {
                    text-align: justify;
                }
            }
        }
    
        .description {
            width: $sizePercent;
            height: $size * 5 + px;
            background-image: url('../assets/images/bg.jpg');
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center bottom;
            background-attachment: fixed;
            @include position(relative);
            @include setFlex(flex, row, center, center);
    
            .description-text {
                color: $secondaryColor;
                font-size: 1.2rem;
                text-align: center;
                width: 60%;
            }
        }
    
        .services {
            @include setPadding(0 100px 50px);
    
            .services-title {
                width: $sizePercent;
                font-size: 1.5rem;
                font-family: "Closer Mint type Semibold";
                text-transform: uppercase;
                @include setPadding(70px 20px 70px 40px);
                @include position(relative);
    
                &:before {
                    content: "";
                    @include position(absolute);
                    top: 85px;
                    left: 0;
                    width: $size/4 + px;
                    height: $size/50 + px;
                    background-color: $primaryColor;
                }
            }
    
            .services-wrapper {
                width: $sizePercent;
                @include setFlex(flex, row, space-between, flex-start);
                flex-wrap: wrap;
    
                .services-item {
                    width: 48%;
                    overflow: hidden;
                    transition: 0.3s ease-out;
                    @include setFlex(flex, column, flex-start, flex-start);
                    height: $size * 4 + px;
                    @include setMargin(0 0 50px);
                    @include position(relative);
    
                    &:hover .services-text {
                        background-color: rgba(33, 37, 41, 0.9);
                        width: $sizePercent;
                        left: 0;
                        display: block;
                    }
        
                    &:hover .item-service-title {
                        display: none;
                    }
    
                    .services-image-commercial,
                    .services-image-workspace,
                    .services-image-interior {
                        width: $sizePercent;
                        height: $sizePercent;
                        background-image: url(../assets/images/services-item-01.jpg);
                        background-size: cover;
                        background-position-y: bottom;
                        @include setFlex(flex, row, center, flex-end);
    
                        .item-service-title {
                            @include setPadding(0 0 35px);
                            font-size: 1.2rem;
                            text-transform: uppercase;
                            color: $secondaryColor;
                            font-weight: bold;
                        }
                    }
    
                    .services-image-workspace {
                        background-image: url(../assets/images/services-item-02.jpg);
                    }
    
                    .services-image-interior {
                        background-image: url(../assets/images/services-item-03.jpg);
                    }
    
                    .services-text {
                        display: none;
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        text-align: center;
    
                        .item-article {
                            position: absolute;
                            top: 44%;
                            left: 0;
                            @include setPadding(0 40px);
                            width: $sizePercent;
                            color: $secondaryColor;
                            animation: entry 0.4s;
                        }
    
                        @keyframes entry {
                            from {
                                top: 150%;
                            }
    
                            to {
                                top: 44%;
                            }
                        }
                    }
    
                    .services-image-works {
                        width: $sizePercent;
                        height: $sizePercent;
                        background-color: black;
                        @include position(relative);
                        @include setFlex(flex, row, center, center);
    
                        .grid__link {
                            width: $sizePercent;
                            height: $sizePercent;
                            @include setMargin(0 auto);
                            @include setFlex(flex, row, center, center);
                            text-decoration: none;
                            text-transform: uppercase;
                            color: $secondaryColor;
                            font-family: $fontBold;
                            font-size: $textFontSize;
                            transition: 0.5s ease-out;
                            line-height: 1.5;
    
                            &:hover {
                              transform: scale(1.25);
                              background-color: $mainColor;
                              color: $primaryColor;
                              @include bor(3px solid $mainColor);
                            }
                        }
                    }
                }
            }
        }
    }
}

