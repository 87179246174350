@import './../tools.scss';


@media screen and (min-width: 375px) and (max-width:575px){
    .workflow-wrapper {
        width: $sizePercent;
        @include setPadding(100px 20px 50px);
    
        .works__grid {
            width: $sizePercent;
            display: grid;
            grid-gap: 10px;
            grid-template-columns: repeat(10, 2fr);
            grid-template-rows: repeat(28, 100px);
            grid-auto-rows: 20px;
            grid-auto-columns: 18px;
            @include setPadding(0 20px);
    
            .grid__item {
                text-align: center;
                background-color: $mainColor;
                background-size: cover;
                @include setFlex(flex, column, center, center);
    
                &:nth-child(1) {
                    grid-column: span 10;
                    grid-row: span 2;
                }
    
                &:nth-child(2) {
                    grid-column: span 10;
                    grid-row: span 2;
                }
    
                &:nth-child(3) {
                    grid-column: span 10;
                    grid-row: span 2;
                }
    
                &:nth-child(4) {
                    grid-column: span 10;
                    grid-row: span 2;
                }
    
                &:nth-child(5) {
                    grid-column: span 10;
                    grid-row: span 2;
                }
    
                &:nth-child(6) {
                    grid-column: span 10;
                    grid-row: span 2;
                }
    
                &:nth-child(7) {
                    grid-column: span 10;
                    grid-row: span 2;
                }
    
                &:nth-child(8) {
                    grid-column: span 10;
                    grid-row: span 2;
                }
    
                &:nth-child(9) {
                    grid-column: span 10;
                    grid-row: span 2;
                }
    
                &:nth-child(10) {
                    grid-column: span 10;
                    grid-row: span 2;
                }
    
                &:nth-child(11) {
                    grid-column: span 10;
                    grid-row: span 2;
                }
    
                &:nth-child(12) {
                    grid-column: span 10;
                    grid-row: span 2;
                }
    
                &:nth-child(13) {
                    grid-column: span 10;
                    grid-row: span 2;
                }
    
                &:nth-child(14) {
                    grid-column: span 10;
                    grid-row: span 2;
                }
    
                .grid__link {
                    width: $sizePercent;
                    height: $sizePercent;
                    @include setMargin(0 auto);
                    overflow: hidden;
    
                    &:hover {
                        cursor: pointer;
                    }
    
                    .image {
                        width: $sizePercent;
                        height: $sizePercent;
                        object-fit: cover;
                        flex-shrink: 0;
                    }
                }
            }
        }
    }
}


@media screen and (min-width: 576px) and (max-width:767px){
    .workflow-wrapper {
        width: $sizePercent;
        @include setPadding(100px 20px 50px);
    
        .works__grid {
            width: $sizePercent;
            display: grid;
            grid-gap: 10px;
            grid-template-columns: repeat(10, 2fr);
            grid-template-rows: repeat(28, 100px);
            grid-auto-rows: 20px;
            grid-auto-columns: 18px;
            @include setPadding(0 20px);
    
            .grid__item {
                text-align: center;
                background-color: $mainColor;
                background-size: cover;
                @include setFlex(flex, column, center, center);
    
                &:nth-child(1) {
                    grid-column: span 10;
                    grid-row: span 2;
                }
    
                &:nth-child(2) {
                    grid-column: span 10;
                    grid-row: span 2;
                }
    
                &:nth-child(3) {
                    grid-column: span 10;
                    grid-row: span 2;
                }
    
                &:nth-child(4) {
                    grid-column: span 10;
                    grid-row: span 2;
                }
    
                &:nth-child(5) {
                    grid-column: span 10;
                    grid-row: span 2;
                }
    
                &:nth-child(6) {
                    grid-column: span 10;
                    grid-row: span 2;
                }
    
                &:nth-child(7) {
                    grid-column: span 10;
                    grid-row: span 2;
                }
    
                &:nth-child(8) {
                    grid-column: span 10;
                    grid-row: span 2;
                }
    
                &:nth-child(9) {
                    grid-column: span 10;
                    grid-row: span 2;
                }
    
                &:nth-child(10) {
                    grid-column: span 10;
                    grid-row: span 2;
                }
    
                &:nth-child(11) {
                    grid-column: span 10;
                    grid-row: span 2;
                }
    
                &:nth-child(12) {
                    grid-column: span 10;
                    grid-row: span 2;
                }
    
                &:nth-child(13) {
                    grid-column: span 10;
                    grid-row: span 2;
                }
    
                &:nth-child(14) {
                    grid-column: span 10;
                    grid-row: span 2;
                }
    
                .grid__link {
                    width: $sizePercent;
                    height: $sizePercent;
                    @include setMargin(0 auto);
                    overflow: hidden;
    
                    &:hover {
                        cursor: pointer;
                    }
    
                    .image {
                        width: $sizePercent;
                        height: $sizePercent;
                        object-fit: cover;
                        flex-shrink: 0;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 768px) and (max-width:991px){
    .workflow-wrapper {
        width: $sizePercent;
        @include setPadding(150px 20px 50px);
    
        .works__grid {
            width: $sizePercent;
            display: grid;
            grid-gap: 10px;
            grid-template-columns: repeat(10, 2fr);
            grid-template-rows: repeat(14, 100px);
            grid-auto-rows: 20px;
            grid-auto-columns: 18px;
            @include setPadding(0 20px);
    
            .grid__item {
                text-align: center;
                background-color: $mainColor;
                background-size: cover;
                @include setFlex(flex, column, center, center);
    
                &:nth-child(1) {
                    grid-column: span 5;
                    grid-row: span 2;
                }
    
                &:nth-child(2) {
                    grid-column: span 5;
                    grid-row: span 2;
                }
    
                &:nth-child(3) {
                    grid-column: span 5;
                    grid-row: span 2;
                }
    
                &:nth-child(4) {
                    grid-column: span 5;
                    grid-row: span 2;
                }
    
                &:nth-child(5) {
                    grid-column: span 5;
                    grid-row: span 2;
                }
    
                &:nth-child(6) {
                    grid-column: span 5;
                    grid-row: span 2;
                }
    
                &:nth-child(7) {
                    grid-column: span 5;
                    grid-row: span 2;
                }
    
                &:nth-child(8) {
                    grid-column: span 5;
                    grid-row: span 2;
                }
    
                &:nth-child(9) {
                    grid-column: span 5;
                    grid-row: span 2;
                }
    
                &:nth-child(10) {
                    grid-column: span 5;
                    grid-row: span 2;
                }
    
                &:nth-child(11) {
                    grid-column: span 5;
                    grid-row: span 2;
                }
    
                &:nth-child(12) {
                    grid-column: span 5;
                    grid-row: span 2;
                }
    
                &:nth-child(13) {
                    grid-column: span 5;
                    grid-row: span 2;
                }
    
                &:nth-child(14) {
                    grid-column: span 5;
                    grid-row: span 2;
                }
    
                .grid__link {
                    width: $sizePercent;
                    height: $sizePercent;
                    @include setMargin(0 auto);
                    overflow: hidden;
    
                    &:hover {
                        cursor: pointer;
                    }
    
                    .image {
                        width: $sizePercent;
                        height: $sizePercent;
                        object-fit: cover;
                        flex-shrink: 0;
                    }
                }
            }
        }
    }
}


@media screen and (min-width: 992px) and (max-width:1199px){
    .workflow-wrapper {
        width: $sizePercent;
        @include setPadding(150px 20px 50px);
    
        .works__grid {
            width: $sizePercent;
            display: grid;
            grid-gap: 10px;
            grid-template-columns: repeat(9, 2fr);
            grid-template-rows: repeat(12, 100px);
            grid-auto-rows: 20px;
            grid-auto-columns: 18px;
            @include setPadding(0 20px);
    
            .grid__item {
                text-align: center;
                background-color: $mainColor;
                background-size: cover;
                @include setFlex(flex, column, center, center);
    
                &:nth-child(1) {
                    grid-column: span 3;
                    grid-row: span 2;
                }
    
                &:nth-child(2) {
                    grid-column: span 3;
                    grid-row: span 2;
                }
    
                &:nth-child(3) {
                    grid-column: span 3;
                    grid-row: span 4;
                }
    
                &:nth-child(4) {
                    grid-column: span 6;
                    grid-row: span 2;
                }
    
                &:nth-child(5) {
                    grid-column: span 3;
                    grid-row: span 2;
                }
    
                &:nth-child(6) {
                    grid-column: span 3;
                    grid-row: span 2;
                }
    
                &:nth-child(7) {
                    grid-column: span 3;
                    grid-row: span 2;
                }
    
                &:nth-child(8) {
                    grid-column: span 3;
                    grid-row: span 2;
                }
    
                &:nth-child(9) {
                    grid-column: span 3;
                    grid-row: span 2;
                }
    
                &:nth-child(10) {
                    grid-column: span 3;
                    grid-row: span 2;
                }
    
                &:nth-child(11) {
                    grid-column: span 6;
                    grid-row: span 2;
                }
    
                &:nth-child(12) {
                    grid-column: span 3;
                    grid-row: span 2;
                }
    
                &:nth-child(13) {
                    grid-column: span 3;
                    grid-row: span 2;
                }
    
                &:nth-child(14) {
                    grid-column: span 6;
                    grid-row: span 2;
                }
    
                .grid__link {
                    width: $sizePercent;
                    height: $sizePercent;
                    @include setMargin(0 auto);
                    overflow: hidden;
    
                    &:hover {
                        cursor: pointer;
                    }
    
                    .image {
                        width: $sizePercent;
                        height: $sizePercent;
                        object-fit: cover;
                        flex-shrink: 0;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 1200px) and (max-width:1399px){
    .workflow-wrapper {
        width: $sizePercent;
        @include setPadding(150px 20px 50px);
    
        .works__grid {
            width: $sizePercent;
            display: grid;
            grid-gap: 10px;
            grid-template-columns: repeat(12, 2fr);
            grid-template-rows: repeat(10, 100px);
            grid-auto-rows: 20px;
            grid-auto-columns: 18px;
            @include setPadding(0 20px);
    
            .grid__item {
                text-align: center;
                background-color: $mainColor;
                background-size: cover;
                @include setFlex(flex, column, center, center);
    
                &:nth-child(1) {
                    grid-column: span 4;
                    grid-row: span 2;
                }
    
                &:nth-child(2) {
                    grid-column: span 4;
                    grid-row: span 2;
                }
    
                &:nth-child(3) {
                    grid-column: span 4;
                    grid-row: span 2;
                }
    
                &:nth-child(4) {
                    grid-column: span 6;
                    grid-row: span 2;
                }
    
                &:nth-child(5) {
                    grid-column: span 6;
                    grid-row: span 2;
                }
    
                &:nth-child(6) {
                    grid-column: span 4;
                    grid-row: span 2;
                }
    
                &:nth-child(7) {
                    grid-column: span 4;
                    grid-row: span 2;
                }
    
                &:nth-child(8) {
                    grid-column: span 4;
                    grid-row: span 2;
                }
    
                &:nth-child(9) {
                    grid-column: span 8;
                    grid-row: span 2;
                }
    
                &:nth-child(10) {
                    grid-column: span 4;
                    grid-row: span 2;
                }
    
                &:nth-child(11) {
                    grid-column: span 3;
                    grid-row: span 2;
                }
    
                &:nth-child(12) {
                    grid-column: span 3;
                    grid-row: span 2;
                }
    
                &:nth-child(13) {
                    grid-column: span 3;
                    grid-row: span 2;
                }
    
                &:nth-child(14) {
                    grid-column: span 3;
                    grid-row: span 2;
                }
    
                .grid__link {
                    width: $sizePercent;
                    height: $sizePercent;
                    @include setMargin(0 auto);
                    overflow: hidden;
    
                    &:hover {
                        cursor: pointer;
                    }
    
                    .image {
                        width: $sizePercent;
                        height: $sizePercent;
                        object-fit: cover;
                        flex-shrink: 0;
                    }
                }
            }
        }
    }
}



@media screen and (min-width: 1400px) and (max-width:1920px){
    .workflow-wrapper {
        width: $sizePercent;
        @include setPadding(200px 100px 50px);
    
        .works__grid {
            width: $sizePercent;
            display: grid;
            grid-gap: 10px;
            grid-template-columns: repeat(10, 2fr);
            grid-template-rows: repeat(5, 100px);
            grid-auto-rows: 20px;
            grid-auto-columns: 18px;
            @include setPadding(0 20px);
    
            .grid__item {
                text-align: center;
                background-color: $mainColor;
                background-size: cover;
                @include setFlex(flex, row, center, center);
                // box-shadow: 0px 6px 20px 0px rgba(0,0,0,0.79);
    
                &:nth-child(1) {
                    grid-column: span 2;
                    grid-row: span 2;
                }
    
                &:nth-child(2) {
                    grid-column: span 2;
                    grid-row: span 2;
                }
    
                &:nth-child(3) {
                    grid-column: span 4;
                    grid-row: span 4;
                }
    
                &:nth-child(4) {
                    grid-column: span 2;
                    grid-row: span 2;
                }
    
                &:nth-child(5) {
                    grid-column: span 4;
                    grid-row: span 2;
                }
    
                &:nth-child(6) {
                    grid-column: span 2;
                    grid-row: span 2;
                }
    
                &:nth-child(7) {
                    grid-column: span 3;
                    grid-row: span 5;
                }
    
                &:nth-child(8) {
                    grid-column: span 5;
                    grid-row: span 10;
                }
    
                &:nth-child(9) {
                    grid-column: span 2;
                    grid-row: span 10;
                }
    
                &:nth-child(10) {
                    grid-column: span 3;
                    grid-row: span 12;
                }
    
                &:nth-child(11) {
                    grid-column: span 3;
                    grid-row: span 7;
                }
    
                &:nth-child(12) {
                    grid-column: span 2;
                    grid-row: span 15;
                }
    
                &:nth-child(13) {
                    grid-column: span 2;
                    grid-row: span 15;
                }
    
                &:nth-child(14) {
                    grid-column: span 6;
                    grid-row: span 8;
                }
    
                .grid__link {
                    width: $sizePercent;
                    height: $sizePercent;
                    @include setMargin(0 auto);
                    overflow: hidden;
    
                    &:hover {
                        cursor: pointer;
                    }
    
                    .image {
                        width: $sizePercent;
                        height: $sizePercent;
                        object-fit: cover;
                        flex-shrink: 0;
                    }
                }
            }
        }
    }
}





