@import './../tools.scss';

@media screen and (min-width: 375px) and (max-width:575px){
  .header {
    width: $sizePercent;
    background-color: $mainColor2;
    @include setPadding(10px 20px);
    @include position(fixed);
    z-index: 5000;
  
    .header-container {
      width: $sizePercent;
      @include setFlex(flex, row, space-between, center);
  
      .logo-wrapper {
        width: calc($sizePercent / 100 * 40);
  
        .logo-wrapper-link {
          width: $sizePercent;
          cursor: pointer;
  
          .logo-wrapper-img {
            width: $sizePercent;
          }
        }
      }
  
      .burger-wrapper {
        width: calc($sizePercent / 100 * 40);
        @include setFlex(flex, row, flex-end, center);
  
        .phone {
          display: none;
          color: $secondaryColor;
          font-size: 1rem;
          font-weight: bold;
          transition: color 0.3s ease;
  
          .phone-text {
            @include setMargin(0 0 0 10px);
          }
  
          &:hover {
            color: $primaryColor;
          }
        }
  
        .burger-button {
          width: 30px;
          height: 20px;
          background: none;
          cursor: pointer;
          @include setFlex(flex, column, space-between, center);
  
          &:hover .burger-button-item {
            background-color: $primaryColor;
          }
  
          .burger-button-item {
            width: 100%;
            height: 1px;
            background-color: $secondaryColor;
          }
        }
      }
    }
  
    .aside {
      @include position(fixed);
      @include setPadding(10px 20px);
      top: 0;
      right: -100%;
      width: $sizePercent;
      height: $sizePercent;
      background-color: $mainColor;
      transition: right 0.3s ease-in-out;
      z-index: 1000;
  
      &.open {
        right: 0;
      }
  
      .aside-top {
        width: $sizePercent;
        @include setFlex(flex, row, space-between, center);
  
        .logo-wrapper {
          width: calc($sizePercent / 100 * 40);
  
          .logo-wrapper-link {
            width: $sizePercent;
            cursor: pointer;
  
            .logo-wrapper-img {
              width: $sizePercent;
            }
          }
        }
  
        .x-button {
          width: 30px;
          height: 30px;
          background: none;
          border: none;
          cursor: pointer;
          @include setFlex(flex, column, center, center);
  
          &:hover .x-button-item {
            background-color: $primaryColor;
          }
  
          .x-button-item {
            width: 100%;
            height: 1px;
            background-color: $secondaryColor;
            transition: background-color 0.3s ease;
  
            &:nth-child(1) {
              @include setMargin(-1px);
              transform: rotate(45deg);
            }
  
            &:nth-child(2) {
              transform: rotate(-45deg);
            }
          }
        }
      }
  
      .aside-links {
        width: $sizePercent;
        @include setPadding(100px 20px);
  
        .aside-links-list {
          width: $sizePercent;
          @include setFlex(flex, column, space-around, center);
  
          .aside-links-list-item {
            width: 100%;
            @include setFlex(flex, row, center, center);
            list-style: none;
            @include setPadding(25px 0);
  
            &:nth-child(1) {
              border-top: 2px solid $primaryColor;
            }
  
            &:nth-child(2) {
              border-top: 2px solid $primaryColor;
              border-bottom: 2px solid $primaryColor;
            }
  
            &:nth-child(3) {
              border-bottom: 2px solid $primaryColor;
            }
  
            .aside-links-list-item-link {
              background: none;
              color: $secondaryColor;
              font-size: 1.2rem;
              text-transform: uppercase;
              cursor: pointer;
              transition: transform 0.3s ease;
              @include position(relative);
  
              &:hover {
                transform: scale(0.8);
              }
  
              &:before {
                content: '';
                @include position(absolute);
                width: 0;
                height: 2px;
                bottom: -35px;
                left: 50%;
                transform: translateX(-50%);
                background-color: $primaryColor;
                transition: width 0.3s ease;
              }
  
              &:hover:before {
                width: 50%;
              }
            }
          }
        }
      }
  
      .aside-contact {
        width: $sizePercent;
        @include setFlex(flex, column, center, center);
        @include setPadding(50px 20px 0);
  
        .phone {
          color: $secondaryColor;
          font-size: 0.8rem;
          font-weight: bold;
          transition: color 0.3s ease;
          @include setMargin(0 0 20px 0);
  
          .phone-text {
            @include setMargin(0 0 0 10px);
          }
  
          &:hover {
            color: $primaryColor;
          }
        }
      }
    }
  }
}


@media screen and (min-width: 576px) and (max-width:767px){
  .header {
    width: $sizePercent;
    background-color: $mainColor2;
    @include setPadding(10px 20px);
    @include position(fixed);
    z-index: 5000;
  
    .header-container {
      width: $sizePercent;
      @include setFlex(flex, row, space-between, center);
  
      .logo-wrapper {
        width: calc($sizePercent / 100 * 35);
  
        .logo-wrapper-link {
          width: $sizePercent;
          cursor: pointer;
  
          .logo-wrapper-img {
            width: $sizePercent;
          }
        }
      }
  
      .burger-wrapper {
        width: calc($sizePercent / 100 * 40);
        @include setFlex(flex, row, flex-end, center);
  
        .phone {
          display: none;
          color: $secondaryColor;
          font-size: 1rem;
          font-weight: bold;
          transition: color 0.3s ease;
  
          .phone-text {
            @include setMargin(0 0 0 10px);
          }
  
          &:hover {
            color: $primaryColor;
          }
        }
  
        .burger-button {
          width: 30px;
          height: 20px;
          background: none;
          cursor: pointer;
          @include setFlex(flex, column, space-between, center);
  
          &:hover .burger-button-item {
            background-color: $primaryColor;
          }
  
          .burger-button-item {
            width: 100%;
            height: 1px;
            background-color: $secondaryColor;
          }
        }
      }
    }
  
    .aside {
      @include position(fixed);
      @include setPadding(10px 20px);
      top: 0;
      right: -100%;
      width: $sizePercent;
      height: $sizePercent;
      background-color: $mainColor;
      transition: right 0.3s ease-in-out;
      z-index: 1000;
  
      &.open {
        right: 0;
      }
  
      .aside-top {
        width: $sizePercent;
        @include setFlex(flex, row, space-between, center);
  
        .logo-wrapper {
          width: calc($sizePercent / 100 * 35);
  
          .logo-wrapper-link {
            width: $sizePercent;
            cursor: pointer;
  
            .logo-wrapper-img {
              width: $sizePercent;
            }
          }
        }
  
        .x-button {
          width: 30px;
          height: 30px;
          background: none;
          border: none;
          cursor: pointer;
          @include setFlex(flex, column, center, center);
  
          &:hover .x-button-item {
            background-color: $primaryColor;
          }
  
          .x-button-item {
            width: 100%;
            height: 1px;
            background-color: $secondaryColor;
            transition: background-color 0.3s ease;
  
            &:nth-child(1) {
              @include setMargin(-1px);
              transform: rotate(45deg);
            }
  
            &:nth-child(2) {
              transform: rotate(-45deg);
            }
          }
        }
      }
  
      .aside-links {
        width: $sizePercent;
        @include setPadding(100px 20px);
  
        .aside-links-list {
          width: $sizePercent;
          @include setFlex(flex, column, space-around, center);
  
          .aside-links-list-item {
            width: 100%;
            @include setFlex(flex, row, center, center);
            list-style: none;
            @include setPadding(25px 0);
  
            &:nth-child(1) {
              border-top: 2px solid $primaryColor;
            }
  
            &:nth-child(2) {
              border-top: 2px solid $primaryColor;
              border-bottom: 2px solid $primaryColor;
            }
  
            &:nth-child(3) {
              border-bottom: 2px solid $primaryColor;
            }
  
            .aside-links-list-item-link {
              background: none;
              color: $secondaryColor;
              font-size: 1.2rem;
              text-transform: uppercase;
              cursor: pointer;
              transition: transform 0.3s ease;
              @include position(relative);
  
              &:hover {
                transform: scale(0.8);
              }
  
              &:before {
                content: '';
                @include position(absolute);
                width: 0;
                height: 2px;
                bottom: -35px;
                left: 50%;
                transform: translateX(-50%);
                background-color: $primaryColor;
                transition: width 0.3s ease;
              }
  
              &:hover:before {
                width: 50%;
              }
            }
          }
        }
      }
  
      .aside-contact {
        width: $sizePercent;
        @include setFlex(flex, column, center, center);
        @include setPadding(50px 20px 0);
  
        .phone {
          color: $secondaryColor;
          font-size: 0.8rem;
          font-weight: bold;
          transition: color 0.3s ease;
          @include setMargin(0 0 20px 0);
  
          .phone-text {
            @include setMargin(0 0 0 10px);
          }
  
          &:hover {
            color: $primaryColor;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width:991px){
  .header {
    width: $sizePercent;
    background-color: $mainColor2;
    @include setPadding(10px 20px);
    @include position(fixed);
    z-index: 5000;
  
    .header-container {
      width: $sizePercent;
      @include setFlex(flex, row, space-between, center);
  
      .logo-wrapper {
        width: calc($sizePercent / 100 * 35);
  
        .logo-wrapper-link {
          width: $sizePercent;
          cursor: pointer;
  
          .logo-wrapper-img {
            width: $sizePercent;
          }
        }
      }
  
      .burger-wrapper {
        width: calc($sizePercent / 100 * 40);
        @include setFlex(flex, row, flex-end, center);
  
        .phone {
          display: none;
          color: $secondaryColor;
          font-size: 1rem;
          font-weight: bold;
          transition: color 0.3s ease;
  
          .phone-text {
            @include setMargin(0 0 0 10px);
          }
  
          &:hover {
            color: $primaryColor;
          }
        }
  
        .burger-button {
          width: 30px;
          height: 20px;
          background: none;
          cursor: pointer;
          @include setFlex(flex, column, space-between, center);
  
          &:hover .burger-button-item {
            background-color: $primaryColor;
          }
  
          .burger-button-item {
            width: 100%;
            height: 1px;
            background-color: $secondaryColor;
          }
        }
      }
    }
  
    .aside {
      @include position(fixed);
      @include setPadding(10px 20px);
      top: 0;
      right: -100%;
      width: $sizePercent;
      height: $sizePercent;
      background-color: $mainColor;
      transition: right 0.3s ease-in-out;
      z-index: 1000;
  
      &.open {
        right: 0;
      }
  
      .aside-top {
        width: $sizePercent;
        @include setFlex(flex, row, space-between, center);
  
        .logo-wrapper {
          width: calc($sizePercent / 100 * 35);
  
          .logo-wrapper-link {
            width: $sizePercent;
            cursor: pointer;
  
            .logo-wrapper-img {
              width: $sizePercent;
            }
          }
        }
  
        .x-button {
          width: 30px;
          height: 30px;
          background: none;
          border: none;
          cursor: pointer;
          @include setFlex(flex, column, center, center);
  
          &:hover .x-button-item {
            background-color: $primaryColor;
          }
  
          .x-button-item {
            width: 100%;
            height: 1px;
            background-color: $secondaryColor;
            transition: background-color 0.3s ease;
  
            &:nth-child(1) {
              @include setMargin(-1px);
              transform: rotate(45deg);
            }
  
            &:nth-child(2) {
              transform: rotate(-45deg);
            }
          }
        }
      }
  
      .aside-links {
        width: $sizePercent;
        @include setPadding(100px 20px);
  
        .aside-links-list {
          width: $sizePercent;
          @include setFlex(flex, column, space-around, center);
  
          .aside-links-list-item {
            width: 100%;
            @include setFlex(flex, row, center, center);
            list-style: none;
            @include setPadding(25px 0);
  
            &:nth-child(1) {
              border-top: 2px solid $primaryColor;
            }
  
            &:nth-child(2) {
              border-top: 2px solid $primaryColor;
              border-bottom: 2px solid $primaryColor;
            }
  
            &:nth-child(3) {
              border-bottom: 2px solid $primaryColor;
            }
  
            .aside-links-list-item-link {
              background: none;
              color: $secondaryColor;
              font-size: 1.2rem;
              text-transform: uppercase;
              cursor: pointer;
              transition: transform 0.3s ease;
              @include position(relative);
  
              &:hover {
                transform: scale(0.8);
              }
  
              &:before {
                content: '';
                @include position(absolute);
                width: 0;
                height: 2px;
                bottom: -35px;
                left: 50%;
                transform: translateX(-50%);
                background-color: $primaryColor;
                transition: width 0.3s ease;
              }
  
              &:hover:before {
                width: 50%;
              }
            }
          }
        }
      }
  
      .aside-contact {
        width: $sizePercent;
        @include setFlex(flex, column, center, center);
        @include setPadding(50px 20px 0);
  
        .phone {
          color: $secondaryColor;
          font-size: 0.8rem;
          font-weight: bold;
          transition: color 0.3s ease;
          @include setMargin(0 0 20px 0);
  
          .phone-text {
            @include setMargin(0 0 0 10px);
          }
  
          &:hover {
            color: $primaryColor;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 992px) and (max-width:1199px){
  .header {
    width: $sizePercent;
    background-color: $mainColor2;
    @include setPadding(25px 50px);
    @include position(fixed);
    z-index: 5000;
  
    .header-container {
      width: $sizePercent;
      @include setFlex(flex, row, space-between, center);
  
      .logo-wrapper {
        width: calc($sizePercent / 100 * 13);
  
        .logo-wrapper-link {
          width: $sizePercent;
          cursor: pointer;
  
          .logo-wrapper-img {
            width: $sizePercent;
          }
        }
      }
  
      .burger-wrapper {
        width: calc($sizePercent / 100 * 30);
        @include setFlex(flex, row, space-between, center);
  
        .phone {
          color: $secondaryColor;
          font-size: 1rem;
          font-weight: bold;
          transition: color 0.3s ease;
  
          .phone-text {
            @include setMargin(0 0 0 10px);
          }
  
          &:hover {
            color: $primaryColor;
          }
        }
  
        .burger-button {
          width: 30px;
          height: 20px;
          background: none;
          cursor: pointer;
          @include setFlex(flex, column, space-between, center);
  
          &:hover .burger-button-item {
            background-color: $primaryColor;
          }
  
          .burger-button-item {
            width: 100%;
            height: 2px;
            background-color: $secondaryColor;
          }
        }
      }
    }
  
    .aside {
      @include position(fixed);
      @include setPadding(25px 50px);
      top: 0;
      right: -100%;
      width: $sizePercent;
      height: $sizePercent;
      background-color: $mainColor;
      transition: right 0.3s ease-in-out;
      z-index: 1000;
  
      &.open {
        right: 0;
      }
  
      .aside-top {
        width: $sizePercent;
        @include setFlex(flex, row, space-between, center);
  
        .logo-wrapper {
          width: calc($sizePercent / 100 * 13);
  
          .logo-wrapper-link {
            width: $sizePercent;
            cursor: pointer;
  
            .logo-wrapper-img {
              width: $sizePercent;
            }
          }
        }
  
        .x-button {
          width: 30px;
          height: 30px;
          background: none;
          border: none;
          cursor: pointer;
          @include setFlex(flex, column, center, center);
  
          &:hover .x-button-item {
            background-color: $primaryColor;
          }
  
          .x-button-item {
            width: 100%;
            height: 2px;
            background-color: $secondaryColor;
            transition: background-color 0.3s ease;
  
            &:nth-child(1) {
              @include setMargin(-1.5px);
              transform: rotate(45deg);
            }
  
            &:nth-child(2) {
              transform: rotate(-45deg);
            }
          }
        }
      }
  
      .aside-links {
        width: $sizePercent;
        @include setPadding(200px 50px);
  
        .aside-links-list {
          width: $sizePercent;
          @include setFlex(flex, row, space-around, center);
  
          .aside-links-list-item {
            width: 30%;
            @include setFlex(flex, row, center, center);
            list-style: none;
            @include setPadding(25px 0);
  
            &:nth-child(1) {
              border-left: 2px solid $primaryColor;
            }
  
            &:nth-child(2) {
              border-left: 2px solid $primaryColor;
              border-right: 2px solid $primaryColor;
            }
  
            &:nth-child(3) {
              border-right: 2px solid $primaryColor;
            }
  
            .aside-links-list-item-link {
              background: none;
              color: $secondaryColor;
              font-size: 1.2rem;
              text-transform: uppercase;
              cursor: pointer;
              transition: transform 0.3s ease;
              @include position(relative);
  
              &:hover {
                transform: scale(0.8);
              }
  
              &:before {
                content: '';
                @include position(absolute);
                width: 0;
                height: 2px;
                bottom: -35px;
                left: 50%;
                transform: translateX(-50%);
                background-color: $primaryColor;
                transition: width 0.3s ease;
              }
  
              &:hover:before {
                width: 50%;
              }
            }
          }
        }
      }
  
      .aside-contact {
        width: $sizePercent;
        @include setFlex(flex, row, space-between, center);
        @include setPadding(50px);
  
        .phone {
          color: $secondaryColor;
          font-size: 1rem;
          font-weight: bold;
          transition: color 0.3s ease;
  
          .phone-text {
            @include setMargin(0 0 0 10px);
          }
  
          &:hover {
            color: $primaryColor;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1200px) and (max-width:1399px){
  .header {
    width: $sizePercent;
    background-color: $mainColor2;
    @include setPadding(25px 50px);
    @include position(fixed);
    z-index: 5000;
  
    .header-container {
      width: $sizePercent;
      @include setFlex(flex, row, space-between, center);
  
      .logo-wrapper {
        width: calc($sizePercent / 100 * 13);
  
        .logo-wrapper-link {
          width: $sizePercent;
          cursor: pointer;
  
          .logo-wrapper-img {
            width: $sizePercent;
          }
        }
      }
  
      .burger-wrapper {
        width: calc($sizePercent / 100 * 30);
        @include setFlex(flex, row, space-between, center);
  
        .phone {
          color: $secondaryColor;
          font-size: 1rem;
          font-weight: bold;
          transition: color 0.3s ease;
  
          .phone-text {
            @include setMargin(0 0 0 10px);
          }
  
          &:hover {
            color: $primaryColor;
          }
        }
  
        .burger-button {
          width: 30px;
          height: 20px;
          background: none;
          cursor: pointer;
          @include setFlex(flex, column, space-between, center);
  
          &:hover .burger-button-item {
            background-color: $primaryColor;
          }
  
          .burger-button-item {
            width: 100%;
            height: 2px;
            background-color: $secondaryColor;
          }
        }
      }
    }
  
    .aside {
      @include position(fixed);
      @include setPadding(25px 50px);
      top: 0;
      right: -100%;
      width: $sizePercent;
      height: $sizePercent;
      background-color: $mainColor;
      transition: right 0.3s ease-in-out;
      z-index: 1000;
  
      &.open {
        right: 0;
      }
  
      .aside-top {
        width: $sizePercent;
        @include setFlex(flex, row, space-between, center);
  
        .logo-wrapper {
          width: calc($sizePercent / 100 * 13);
  
          .logo-wrapper-link {
            width: $sizePercent;
            cursor: pointer;
  
            .logo-wrapper-img {
              width: $sizePercent;
            }
          }
        }
  
        .x-button {
          width: 30px;
          height: 30px;
          background: none;
          border: none;
          cursor: pointer;
          @include setFlex(flex, column, center, center);
  
          &:hover .x-button-item {
            background-color: $primaryColor;
          }
  
          .x-button-item {
            width: 100%;
            height: 2px;
            background-color: $secondaryColor;
            transition: background-color 0.3s ease;
  
            &:nth-child(1) {
              @include setMargin(-1.5px);
              transform: rotate(45deg);
            }
  
            &:nth-child(2) {
              transform: rotate(-45deg);
            }
          }
        }
      }
  
      .aside-links {
        width: $sizePercent;
        @include setPadding(200px 50px);
  
        .aside-links-list {
          width: $sizePercent;
          @include setFlex(flex, row, space-around, center);
  
          .aside-links-list-item {
            width: 30%;
            @include setFlex(flex, row, center, center);
            list-style: none;
            @include setPadding(25px 0);
  
            &:nth-child(1) {
              border-left: 2px solid $primaryColor;
            }
  
            &:nth-child(2) {
              border-left: 2px solid $primaryColor;
              border-right: 2px solid $primaryColor;
            }
  
            &:nth-child(3) {
              border-right: 2px solid $primaryColor;
            }
  
            .aside-links-list-item-link {
              background: none;
              color: $secondaryColor;
              font-size: 1.2rem;
              text-transform: uppercase;
              cursor: pointer;
              transition: transform 0.3s ease;
              @include position(relative);
  
              &:hover {
                transform: scale(0.8);
              }
  
              &:before {
                content: '';
                @include position(absolute);
                width: 0;
                height: 2px;
                bottom: -35px;
                left: 50%;
                transform: translateX(-50%);
                background-color: $primaryColor;
                transition: width 0.3s ease;
              }
  
              &:hover:before {
                width: 50%;
              }
            }
          }
        }
      }
  
      .aside-contact {
        width: $sizePercent;
        @include setFlex(flex, row, space-between, center);
        @include setPadding(50px);
  
        .phone {
          color: $secondaryColor;
          font-size: 1rem;
          font-weight: bold;
          transition: color 0.3s ease;
  
          .phone-text {
            @include setMargin(0 0 0 10px);
          }
  
          &:hover {
            color: $primaryColor;
          }
        }
      }
    }
  }
}


@media screen and (min-width: 1400px) and (max-width:1920px){
  .header {
    width: $sizePercent;
    background-color: $mainColor2;
    @include setPadding(25px 50px);
    @include position(fixed);
    z-index: 5000;
  
    .header-container {
      width: $sizePercent;
      @include setFlex(flex, row, space-between, center);
  
      .logo-wrapper {
        width: calc($sizePercent / 100 * 13);
  
        .logo-wrapper-link {
          width: $sizePercent;
          cursor: pointer;
  
          .logo-wrapper-img {
            width: $sizePercent;
          }
        }
      }
  
      .burger-wrapper {
        width: calc($sizePercent / 100 * 20);
        @include setFlex(flex, row, space-between, center);
  
        .phone {
          color: $secondaryColor;
          font-size: 1rem;
          font-weight: bold;
          transition: color 0.3s ease;
  
          .phone-text {
            @include setMargin(0 0 0 10px);
          }
  
          &:hover {
            color: $primaryColor;
          }
        }
  
        .burger-button {
          width: 30px;
          height: 20px;
          background: none;
          cursor: pointer;
          @include setFlex(flex, column, space-between, center);
  
          &:hover .burger-button-item {
            background-color: $primaryColor;
          }
  
          .burger-button-item {
            width: 100%;
            height: 2px;
            background-color: $secondaryColor;
          }
        }
      }
    }
  
    .aside {
      @include position(fixed);
      @include setPadding(25px 50px);
      top: 0;
      right: -100%;
      width: $sizePercent;
      height: $sizePercent;
      background-color: $mainColor;
      transition: right 0.3s ease-in-out;
      z-index: 1000;
  
      &.open {
        right: 0;
      }
  
      .aside-top {
        width: $sizePercent;
        @include setFlex(flex, row, space-between, center);
  
        .logo-wrapper {
          width: calc($sizePercent / 100 * 13);
  
          .logo-wrapper-link {
            width: $sizePercent;
            cursor: pointer;
  
            .logo-wrapper-img {
              width: $sizePercent;
            }
          }
        }
  
        .x-button {
          width: 30px;
          height: 30px;
          background: none;
          border: none;
          cursor: pointer;
          @include setFlex(flex, column, center, center);
  
          &:hover .x-button-item {
            background-color: $primaryColor;
          }
  
          .x-button-item {
            width: 100%;
            height: 2px;
            background-color: $secondaryColor;
            transition: background-color 0.3s ease;
  
            &:nth-child(1) {
              @include setMargin(-2px);
              transform: rotate(45deg);
            }
  
            &:nth-child(2) {
              transform: rotate(-45deg);
            }
          }
        }
      }
  
      .aside-links {
        width: $sizePercent;
        @include setPadding(200px 100px);
  
        .aside-links-list {
          width: $sizePercent;
          @include setFlex(flex, row, space-around, center);
  
          .aside-links-list-item {
            width: 30%;
            @include setFlex(flex, row, center, center);
            list-style: none;
            @include setPadding(25px 0);
  
            &:nth-child(1) {
              border-left: 2px solid $primaryColor;
            }
  
            &:nth-child(2) {
              border-left: 2px solid $primaryColor;
              border-right: 2px solid $primaryColor;
            }
  
            &:nth-child(3) {
              border-right: 2px solid $primaryColor;
            }
  
            .aside-links-list-item-link {
              background: none;
              color: $secondaryColor;
              font-size: 1.2rem;
              text-transform: uppercase;
              cursor: pointer;
              transition: transform 0.3s ease;
              @include position(relative);
  
              &:hover {
                transform: scale(0.8);
              }
  
              &:before {
                content: '';
                @include position(absolute);
                width: 0;
                height: 2px;
                bottom: -35px;
                left: 50%;
                transform: translateX(-50%);
                background-color: $primaryColor;
                transition: width 0.3s ease;
              }
  
              &:hover:before {
                width: 50%;
              }
            }
          }
        }
      }
  
      .aside-contact {
        width: $sizePercent;
        @include setFlex(flex, row, space-between, center);
        @include setPadding(50px 100px);
  
        .phone {
          color: $secondaryColor;
          font-size: 1rem;
          font-weight: bold;
          transition: color 0.3s ease;
  
          .phone-text {
            @include setMargin(0 0 0 10px);
          }
  
          &:hover {
            color: $primaryColor;
          }
        }
      }
    }
  }
}